import React, {useEffect, useRef, useState} from "react";
import {
    useParams
} from "react-router-dom";
import {doc, onSnapshot, Timestamp} from "firebase/firestore";
import Btn from "../../components/Btn";
import {
    updateRequete,
    addFacture,
    getFacturesFromRequete,
    iaFacture,
    updateFacture,
    deleteFacture,
    addMiseEnDemeure,
    addAccuseReception,
    getVilleFromCodePostal,
    getTJ,
    getTarifEnvoi,
    sendRequeteCourrier,
    getTDC,
    createPayement,
    choosePM,
    updatePaiement,
    getIdEnvoiFromRequeteId,
    sendMEDCourrier,
    getSuiviCourrier,
    safeParseFloat,
    getEnvoi, isDebiteurSocieteCivile, isSocieteCivile, mf_isactive, payerEnvoiCourrier
} from "../../class/Requete";
import {urlSite} from "../../class/Config";
import {getStorage, ref, uploadBytesResumable, getDownloadURL, uploadString} from "firebase/storage";
import {firebaseApp, db} from "../../components/Firebase";
import PDFViewer from "../../components/PDFViewer";
import {closeModal, Modal, openModal} from "../../components/Modal";
import SignaturePad from 'react-signature-pad-wrapper'
import {getFicheIdentite} from "../../class/Infogreffe";
import QRCode from "react-qr-code";
import {getUserFromUid, isAbonne} from "../../class/User";
import {getAbonnement, getPortalURL, stripeDirectGetSession} from "../../class/Abonnement";
import {Helmet} from 'react-helmet';
import {Alert, ProgressBar} from "react-bootstrap";
import {decode} from "html-entities";

export default function Requete() {
    const [etape, setEtape] = useState(0);
    const [requete, setRequete] = useState({});
    const [isSocieteCivileType, setIsSocieteCivileType] = useState();

    const [loadingData, setLoadingData] = useState(true);

    /* Particulier */
    const [titreDebiteur, setTitreDebiteur] = useState("");
    const [prenomDebiteur, setPrenomDebiteur] = useState("");
    const [nomDebiteur, setNomDebiteur] = useState("");
    const [adresseDebiteur, setAdresseDebiteur] = useState("");
    const [codePostalDebiteur, setCodePostalDebiteur] = useState("");
    const [villeDebiteur, setVilleDebiteur] = useState("");
    const [formeJuridiqueDebiteur, setFormeJuridiqueDebiteur] = useState("");
    const [formesJuridiques, setFormesJuridiques] = useState({});
    const [telephoneDebiteur, setTelephoneDebiteur] = useState("");
    const [KBISDebiteur, setKBISDebiteur] = useState(null);


    const [nomEpouxDebiteur, setNomEpouxDebiteur] = useState("");
    const [paysDebiteur, setPaysDebiteur] = useState("");
    const [emailDebiteur, setEmailDebiteur] = useState("");
    const [utilisateur, setUtilisateur] = useState({});
    const [pm, setPM] = useState({});
    const [currentPM, setCurrentPM] = useState("");
    const [pi, setPi] = useState("");
    const [suiviRequete, setSuiviRequete] = useState(null);
    const [canSignMed, setCanSignMed] = useState(false);
    const [canSignRequete, setCanSignRequete] = useState(false);

    const [pays, setPays] = useState({});

    const [tj, setTj] = useState([]);
    const [loadingTJ, setLoadingTJ] = useState(true);

    const [totalEnvoi, settotalEnvoi] = useState(0);
    const [progressEnvoi, setProgressEnvoi] = useState(0);

    /* Entreprise */
    const [SIRENDebiteur, setSIRENDebiteur] = useState("");
    const [fiche_identite, setFiche_identite] = useState("");
    const [SIREN_ok, setSIREN_ok] = useState(false);
    const [RaisonSocialeDebiteur, setRaisonSocialeDebiteur] = useState("");
    //const [SIREN_error, setSIREN_error] = useState(false);


    const [factures, setFactures] = useState([]);
    const [recalculateTotal, setRecalculateTotal] = useState(false);


    const [totalHT, setTotalHT] = useState(0);
    const [totalTVA, setTotalTVA] = useState(0);
    const [totalTTC, setTotalTTC] = useState(0);
    const [totalPercu, setTotalPercu] = useState(0);

    const [idEnvoi, setIdEnvoi] = useState(0);
    const [loadingSendCourrier, setLoadingSendCourrier] = useState(false);


    const [tarifPageMiseEnDemeure, setTarifPageMiseEnDemeure] = useState("");
    const [envoiMED, setEnvoiMED] = useState(null);
    const [suiviMED, setSuiviMED] = useState(null);

    const [mfisinactive, setMfisinactive] = useState(false);

    const signature = useRef(null);
    const formSociete = useRef(null);

    const requeteSend = useRef(false);

    const [ordonnance, setOrdonnance] = useState(null);

    //crediteur
    const [SIREN_crediteur, setSIREN_crediteur] = useState("");
    const [SIREN_ok_crediteur, setSIREN_ok_crediteur] = useState(false);
    const [fiche_identite_crediteur, setfiche_identite_crediteur] = useState({});
    const [RaisonSociale_crediteur, setRaisonSociale_crediteur] = useState("");
    const [Adresse_crediteur, setAdresse_crediteur] = useState("");
    const [CodePostal_crediteur, setCodePostal_crediteur] = useState("");
    const [Ville_crediteur, setVille_crediteur] = useState("");
    const [Prenom_crediteur, setPrenom_crediteur] = useState("");
    const [Nom_crediteur, setNom_crediteur] = useState("");
    const [Qualite_crediteur, setQualite_crediteur] = useState("");
    const [Telephone_crediteur, setTelephone_crediteur] = useState("");
    const [Titre_crediteur, setTitre_crediteur] = useState("");


    let {id} = useParams();

    useEffect(() => {
        const getData = async () => {
            //get session_id params in url
            const urlParams = new URLSearchParams(window.location.search);
            const session_id = urlParams.get('session_id');
            if (session_id) {
                const session = await stripeDirectGetSession(session_id);
                console.log(session);
                if (session && session.payment_status === "paid") {
                    if (session.metadata.action === "miseendemeure" && !requete.date_envoi_med && etape === 13) {
                        let nom = "";
                        let adresse = "";
                        let codepostal = "";
                        let ville = "";
                        if (requete.type === "entreprise") {
                            nom = requete.raisonSocialeDebiteur;
                            adresse = requete.AdresseDebiteur;
                            codepostal = requete.CodePostalDebiteur;
                            ville = requete.VilleDebiteur;
                        } else {
                            nom = requete.PrenomDebiteur + " " + requete.NomDebiteur;
                            adresse = requete.AdresseDebiteur;
                            codepostal = requete.CodePostalDebiteur;
                            ville = requete.VilleDebiteur;
                        }
                        if (!requete.date_envoi_med) {
                            let resultCourrier = await sendMEDCourrier(id, nom, adresse, codepostal, ville);
                            if (resultCourrier.success === true) {
                                await updateRequete(id, {etape: 14, date_envoi_med: Timestamp.now()});
                                setEtape(14);
                            } else {
                                console.log("Une erreur s'est produite lors de l'envoi de votre courrier, veuillez contacter l'administrateur : " + resultCourrier.error.code + " - " + resultCourrier.error.text);
                            }
                        } else {
                            alert("Une erreur s'est produite lors de l'envoi de votre courrier, veuillez contacter l'administrateur.");
                        }

                    } else if (session.metadata.action === "requete"  && !requete.date_envoi_requete && etape === 10) {
                        let tjNom;
                        let tjAdresse;
                        let tjCodepostal;
                        let tjVille;
                        if (requete.type === "entreprise" && !isSocieteCivileType) {
                            let TDC = await getTDC(requete.CodePostalDebiteur);
                            tjNom = "Tribunal du commerce de " + TDC.fields.greffe;
                            tjAdresse = ((TDC.fields.numero_voie ? TDC.fields.numero_voie + " " : "") +
                                (TDC.fields.type_voie ? TDC.fields.type_voie + " " : "") +
                                (TDC.fields.voie ? TDC.fields.voie : "")).trim();
                            tjCodepostal = TDC.fields.code_postal;
                            tjVille = TDC.fields.bureau_distributeur;
                        } else {
                            let TJElement = await getTJ(requete.CodePostalDebiteur);
                            tjNom = TJElement.nom;
                            tjAdresse = TJElement.adresse;
                            tjCodepostal = TJElement.codepostal;
                            tjVille = TJElement.ville;
                        }
                        if (!requete.date_envoi_requete) {
                            let resultCourrier = await sendRequeteCourrier(id, tjNom, tjAdresse, tjCodepostal, tjVille);
                            if (resultCourrier.success === true) {
                                await updateRequete(id, {etape: 11, date_envoi_requete: Timestamp.now()});
                                setEtape(11);
                                if (document.getElementById("modal_confirmer_envoi_requete")) {
                                    closeModal(document.getElementById("modal_confirmer_envoi_requete"));
                                }
                            } else {
                                console.log("Une erreur s'est produite lors de l'envoi de votre courrier, veuillez contacter l'administrateur : " + resultCourrier.error.code + " - " + resultCourrier.error.text);
                            }
                        }

                    }
                } else {
                    alert("Une erreur s'est produite lors du paiement, veuillez contacter l'administrateur.");
                }
            }
        }
        getData();
    }, [id,etape,requete]);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const payment_intent = urlParams.get('payment_intent');
        if (payment_intent) {
            setLoadingSendCourrier(true);
            setPi(payment_intent);
        }
        const getData = async () => {
            let mfisinactive_temp = await mf_isactive();
            setMfisinactive(mfisinactive_temp);
        }
        getData();
    }, []);
    useEffect(() => {
        const requeteListener = () => {

            onSnapshot(doc(db, "requetes", id), async (doc) => {
                let requeteElement = doc.data();
                //const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
                setUtilisateur(await getUserFromUid(requeteElement.uid));
                setRequete(requeteElement);
                setIsSocieteCivileType(await isDebiteurSocieteCivile(id));
                setPrenomDebiteur(requeteElement.PrenomDebiteur);
                setNomDebiteur(requeteElement.NomDebiteur);
                setAdresseDebiteur(requeteElement.AdresseDebiteur);
                setCodePostalDebiteur(requeteElement.CodePostalDebiteur);
                setVilleDebiteur(requeteElement.VilleDebiteur);
                setSIRENDebiteur(requeteElement.SIRENDebiteur);
                setRaisonSocialeDebiteur(requeteElement.raisonSocialeDebiteur);
                setTitreDebiteur(requeteElement.TitreDebiteur);
                setNomEpouxDebiteur(requeteElement.NomEpouxDebiteur);
                setPaysDebiteur(requeteElement.PaysDebiteur);
                setEmailDebiteur(requeteElement.EmailDebiteur);
                setFormeJuridiqueDebiteur(requeteElement.formeJuridiqueDebiteur);
                setTelephoneDebiteur(requeteElement.telephoneDebiteur);
                setKBISDebiteur(requeteElement.KBIS_debiteur);
                setOrdonnance(requeteElement.ordonnance);

                setSIREN_crediteur(requeteElement.SIREN_crediteur);
                setRaisonSociale_crediteur(requeteElement.RaisonSociale_crediteur);
                setAdresse_crediteur(requeteElement.Adresse_crediteur);
                setCodePostal_crediteur(requeteElement.CodePostal_crediteur);
                setVille_crediteur(requeteElement.Ville_crediteur);
                setPrenom_crediteur(requeteElement.Prenom_crediteur);
                setNom_crediteur(requeteElement.Nom_crediteur);
                setQualite_crediteur(requeteElement.Qualite_crediteur);
                setTelephone_crediteur(requeteElement.Telephone_crediteur);
                setTitre_crediteur(requeteElement.Titre_crediteur);


                if (requeteElement.SIRENDebiteur && requeteElement.ficheIdentiteDebiteur && requeteElement.ficheIdentiteDebiteur?.Data?.EtatRadiation !== "true" && requeteElement.ficheIdentiteDebiteur?.Code !== 404) {
                    setSIREN_ok(true);
                }

                if (requeteElement.SIREN_crediteur && requeteElement.fiche_identite_crediteur && requeteElement.fiche_identite_crediteur?.Data?.EtatRadiation !== "true" && requeteElement.fiche_identite_crediteur?.Code !== 404) {
                    setSIREN_ok_crediteur(true);
                }

                if (requeteElement.etape) {
                    setEtape(requeteElement.etape);
                } else {
                    let abonnement = await isAbonne(requeteElement.uid);
                    let premium = await getAbonnement(3);
                    if (!abonnement.items) {
                        setEtape(1);
                    } else {
                        if (abonnement.items.data[0].price.id === premium.stripe_id || abonnement.items.data[0].price.id === premium.stripe_id_prod) {
                            setEtape(17);
                        } else {
                            setEtape(1);
                        }
                    }
                }
                setLoadingData(false);
            });

        }
        requeteListener();
    }, [id]);
    useEffect(() => {
        const fetchData = async () => {
            if (etape === 4) {
                let facturesElements = await getFacturesFromRequete(id);
                setFactures(facturesElements);
                //setRecalculateTotal(!recalculateTotal);
            }
            if (etape === 2) {
                getPays();
            }
            if (etape === 3) {
                let facturesElements = await getFacturesFromRequete(id);
                setFactures(facturesElements);
            }
            if (etape === 10) {
                if (!requete.test) {
                    setIdEnvoi(await getIdEnvoiFromRequeteId(id, "requete"));
                    setPM(await choosePM(utilisateur.stripe_customer_id));
                }
                if (requete.type === "entreprise" && !isSocieteCivileType) {
                    let TDC = await getTDC(codePostalDebiteur);
                    setTj({
                        nom: "Tribunal du commerce de " + TDC.fields.greffe,
                        adresse: ((TDC.fields.numero_voie ? TDC.fields.numero_voie + " " : "") +
                            (TDC.fields.type_voie ? TDC.fields.type_voie + " " : "") +
                            (TDC.fields.voie ? TDC.fields.voie : "")).trim(),
                        codepostal: TDC.fields.code_postal,
                        ville: TDC.fields.bureau_distributeur
                    });
                } else {
                    setTj(await getTJ(codePostalDebiteur));
                }
                setLoadingTJ(false);
                if (requete.nbrPagesRequete) {
                    let totalEnvoi = await getTarifEnvoi(requete.nbrPagesRequete);
                    settotalEnvoi(totalEnvoi.totalTTCMarge);
                }
            }
            if (etape === 12) {
                getFormeJuridique();
            }
            if (etape === 13) {
                //if (!requete.test) {
                setLoadingData(true);
                let totalEnvoiMED = await getTarifEnvoi(requete.nbrPagesMiseEnDemeure);
                if (totalEnvoiMED) {
                    console.log(totalEnvoiMED);
                    setTarifPageMiseEnDemeure(totalEnvoiMED.totalTTCMarge);
                    console.log(utilisateur.stripe_customer_id);
                    if (utilisateur.stripe_customer_id) {
                        setPM(await choosePM(utilisateur.stripe_customer_id));
                    }

                    let envoi = await getIdEnvoiFromRequeteId(id, "miseendemeure");
                    setEnvoiMED(envoi);
                }
                //}
                setLoadingData(false);
            }
            if (etape === 14) {
                setLoadingData(true);
                let envoi = await getIdEnvoiFromRequeteId(id, "miseendemeure");

                let suivi = await getSuiviCourrier(envoi?.result?.envoi_id[0]);
                let envoiResult = await getEnvoi(envoi?.result?.envoi_id[0]);

                const SECONDS_PER_DAY = 24 * 60 * 60;
                const TOTAL_SECONDS = 15 * SECONDS_PER_DAY;

                let now = Math.floor(Date.now() / 1000); // Date actuelle en secondes (unix timestamp)
                /*console.log("envoiResult");
                console.log(envoiResult);*/
                let dateEnvoi = envoiResult.envoi.general.date; // date d'envoi du courrier


                let elapsedSeconds = now - dateEnvoi;
                let progressPercentage = (elapsedSeconds / TOTAL_SECONDS) * 100;
                setProgressEnvoi(progressPercentage);


                /*if (suivi.suiviCourrier) {
                    let proof = await getProof(suivi.suiviCourrier.refTracking);
                    setProofMED(proof);
                }*/
                setSuiviMED(suivi);
                setLoadingData(false);
            }
        }
        fetchData().catch(console.error);
    }, [etape, codePostalDebiteur, id, requete.nbrPagesRequete, requete.type, utilisateur.stripe_customer_id, requete.nbrPagesMiseEnDemeure, isSocieteCivileType, requete.test]);
    const facturesJSON = JSON.stringify(factures);
    useEffect(() => {
        const updateTotal = () => {
            if (factures.length > 0) {
                let totalHTLocal = 0;
                let totalTVALocal = 0;
                let totalTTCLocal = 0;
                let totalPercuLocal = 0;
                factures.map((facture, index) => {
                    factures[index].total_amount = 0;
                    if (facture.net_amount) {
                        totalHTLocal += parseFloat(facture.net_amount);
                        totalTTCLocal += parseFloat(facture.net_amount);
                        factures[index].total_amount += parseFloat(facture.net_amount);
                    }
                    if (facture.total_tax_amount) {
                        totalTVALocal += parseFloat(facture.total_tax_amount);
                        totalTTCLocal += parseFloat(facture.total_tax_amount)
                        factures[index].total_amount += parseFloat(facture.total_tax_amount);
                    }
                    //factures[index].total_amount = parseFloat(facture.net_amount) + parseFloat(facture.total_tax_amount);
                    if (facture.total_received) {
                        totalPercuLocal += parseFloat(facture.total_received);
                    }
                    return true;
                })
                if (isNaN(totalHTLocal)) {
                    totalHTLocal = 0;
                }
                if (isNaN(totalTVALocal)) {
                    totalTVALocal = 0;
                }
                if (isNaN(totalTTCLocal)) {
                    totalTTCLocal = 0;
                }
                if (isNaN(totalPercuLocal)) {
                    totalPercuLocal = 0;
                }


                if (!isNaN(totalHTLocal)) {
                    setTotalHT(totalHTLocal);
                }
                if (!isNaN(totalTVALocal)) {
                    setTotalTVA(totalTVALocal);
                }
                if (!isNaN(totalTTCLocal)) {
                    setTotalTTC(totalTTCLocal);
                }
                if (!isNaN(totalPercuLocal)) {
                    setTotalPercu(totalPercuLocal);
                }
            }
        }
        updateTotal();
    }, [recalculateTotal, facturesJSON, factures]);

    /*useEffect(() => {
    const pid_traitement = async (doc) => {
        let paiement = doc.data();
        if (paiement) {
            if (paiement.status === "succeeded") {
                if (!requeteSend.current) {
                    requeteSend.current = true;
                    if (paiement.libelle === "requete") {
                        let tjNom;
                        let tjAdresse;
                        let tjCodepostal;
                        let tjVille;
                        if (requete.type === "entreprise" && !isSocieteCivileType) {
                            let TDC = await getTDC(requete.CodePostalDebiteur);
                            tjNom = "Tribunal du commerce de " + TDC.fields.greffe;
                            tjAdresse = ((TDC.fields.numero_voie ? TDC.fields.numero_voie + " " : "") +
                                (TDC.fields.type_voie ? TDC.fields.type_voie + " " : "") +
                                (TDC.fields.voie ? TDC.fields.voie : "")).trim();
                            tjCodepostal = TDC.fields.code_postal;
                            tjVille = TDC.fields.bureau_distributeur;
                        } else {
                            let TJElement = await getTJ(requete.CodePostalDebiteur);
                            tjNom = TJElement.nom;
                            tjAdresse = TJElement.adresse;
                            tjCodepostal = TJElement.codepostal;
                            tjVille = TJElement.ville;
                        }
                        let resultCourrier = await sendRequeteCourrier(id, tjNom, tjAdresse, tjCodepostal, tjVille);
                        if (resultCourrier.success === true) {
                            await updateRequete(id, {etape: 11});
                            setEtape(11);
                            if (document.getElementById("modal_confirmer_envoi_requete")) {
                                closeModal(document.getElementById("modal_confirmer_envoi_requete"));
                            }
                        } else {
                            alert("Une erreur s'est produite lors de l'envoi de votre courrier, veuillez contacter l'administrateur.")
                        }
                    }
                    if (paiement.libelle === "miseendemeure") {
                        let nom = "";
                        let adresse = "";
                        let codepostal = "";
                        let ville = "";


                        if (requete.type === "entreprise") {
                            nom = requete.raisonSocialeDebiteur;
                            adresse = requete.AdresseDebiteur;
                            codepostal = requete.CodePostalDebiteur;
                            ville = requete.VilleDebiteur;
                        } else {
                            nom = requete.PrenomDebiteur + " " + requete.NomDebiteur;
                            adresse = requete.AdresseDebiteur;
                            codepostal = requete.CodePostalDebiteur;
                            ville = requete.VilleDebiteur;
                        }
                        let resultCourrier = await sendMEDCourrier(id, nom, adresse, codepostal, ville);
                        if (resultCourrier.success === true) {
                            await updateRequete(id, {etape: 14});
                            setEtape(14);
                            closeModal(document.getElementById("modal_confirmer_envoi_med"));
                        } else {
                            alert("Une erreur s'est produite lors de l'envoi de votre courrier, veuillez contacter l'administrateur.")
                        }
                    }
                }
            }
            if (paiement.status === "requires_action") {
                const urlParams = new URLSearchParams(window.location.search);
                const payment_intent = urlParams.get('payment_intent');
                if (!payment_intent) {
                    document.location.href = paiement.next_action.redirect_to_url.url;
                }
            }
        }
    }
    const getData = async () => {
        if (etape === 10 && pi !== "") {
            onSnapshot(doc(db, "paiements", pi), pid_traitement);
            await updatePaiement(pi, {update: 1});
        }
        if (etape === 13 && pi !== "") {
            onSnapshot(doc(db, "paiements", pi), pid_traitement);
            await updatePaiement(pi, {update: 1});
        }
    }
    getData();
    console.log("Etape :" + etape);
}, [pi, etape, requete, id, isSocieteCivileType]);*/

    const uploadOrdonnance = async (event) => {
        const uploader = document.getElementById('ordonnance_progress');
        uploader.className = "opacity-100 container-fluid";
        const storage = getStorage(firebaseApp);
        const file = event.target.files[0];
        let progress = [];


        let type = file.type;
        let extension = "";
        if (type === "image/jpeg") {
            extension = "jpg";
        } else if (type === "image/png") {
            extension = "png";
        } else if (type === "application/pdf") {
            extension = "pdf";
        } else {
            openModal(document.getElementById("modal_format_fichier"));
        }

        if (extension !== "") {
            const storageRef = ref(storage, 'ordonnances/' + (Date.now().toString(36) + Math.random().toString(36).substring(2)) + "." + extension);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    uploader.value = progress;
                    switch (snapshot.state) {
                        case 'paused':
                            break;
                        case 'running':
                            break;
                        default:

                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                        default:
                    }
                },
                async () => {
                    // Upload completed successfully, now we can get the download URL
                    await getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        setOrdonnance({url: downloadURL, type: type});
                        await updateRequete(id, {
                            ordonnance: {url: downloadURL, type: type},
                            statut_huissier: "ordonnance envoyee",
                            date_envoi_huissier: Timestamp.now()
                        });
                    });
                }
            );
        }
    }
    const updateEtape = async (etape, requeteData) => {
        setLoadingData(true);
        await updateRequete(id, requeteData)
        setEtape(etape);
        setRequete({...requete, ...requeteData})
    }
    const isParticulier = async (endLoading) => {
        let requeteData = {type: "particulier", etape: 2};
        await updateEtape(2, requeteData);
        endLoading();
    }
    const isEntreprise = async (endLoading) => {
        let requeteData = {type: "entreprise", etape: 12};
        await updateEtape(12, requeteData);
        endLoading();
    }
    const updateDebiteur = async (endLoading) => {
        if (paysDebiteur !== "FR") {
            openModal(document.getElementById("pays_error"));
        } else {
            let form = document.getElementById("formParticulier");
            if (!form.checkValidity()) {

            } else {
                let requeteData = {
                    PrenomDebiteur: prenomDebiteur,
                    NomDebiteur: nomDebiteur,
                    AdresseDebiteur: adresseDebiteur,
                    CodePostalDebiteur: codePostalDebiteur,
                    VilleDebiteur: villeDebiteur,
                    TitreDebiteur: titreDebiteur,
                    NomEpouxDebiteur: nomEpouxDebiteur,
                    PaysDebiteur: paysDebiteur,
                    PaysDebiteurFR: pays[paysDebiteur],
                    EmailDebiteur: emailDebiteur,
                    etape: 3
                };
                await updateEtape(3, requeteData);
                setEtape(3);
            }
            form.classList.add('was-validated');
        }

        endLoading();
    }
    const getPays = () => {
        fetch('/assets/json/pays.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setPays(myJson);
            });
    }
    const getFormeJuridique = () => {
        fetch('/assets/json/forme-juridique.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setFormesJuridiques(myJson);
            });
    }
    const uploadNewFactures = async (event) => {
        if (event.target.files.length > 30) {
            alert("Vous ne pouvez pas télécharger plus de 30 factures dans un dossier.");
            return; // Sortir de la fonction si plus de 30 fichiers sont sélectionnés
        }

        const files = Array.from(event.target.files);
        const maxSize = 10 * 1024 * 1024; // 10 Mo en octets
        const validTypes = ["image/jpeg", "image/png", "application/pdf"];

        for (const file of files) {
            if (!validTypes.includes(file.type)) {
                alert("Certains fichiers ne sont pas du type autorisé (JPG, PNG, PDF).");
                return; // Sortir de la fonction si un type de fichier n'est pas autorisé
            }
            if (file.size > maxSize) {
                alert("Certains fichiers dépassent la taille maximale de 10 Mo.");
                return; // Sortir de la fonction si un fichier dépasse la taille maximale
            }
        }

        // Si tous les fichiers passent les vérifications, continuez avec l'upload
        uploadFactures(event, true);
    }
    const uploadKbisDebiteur = (event, updateFactures) => {
        const uploader = document.getElementById('uploader');
        uploader.className = "opacity-100 container-fluid";
        const storage = getStorage(firebaseApp);
        const file = event.target.files[0];
        let progress = [];


        let type = file.type;
        let extension = "";
        if (type === "image/jpeg") {
            extension = "jpg";
        } else if (type === "image/png") {
            extension = "png";
        } else if (type === "application/pdf") {
            extension = "pdf";
        } else {
            openModal(document.getElementById("modal_format_fichier"));
        }

        if (extension !== "") {
            const storageRef = ref(storage, 'kbis_debiteur/' + (Date.now().toString(36) + Math.random().toString(36).substring(2)) + "." + extension);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    uploader.value = progress;
                    switch (snapshot.state) {
                        case 'paused':
                            break;
                        case 'running':
                            break;
                        default:

                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                        default:
                    }
                },
                async () => {
                    // Upload completed successfully, now we can get the download URL
                    await getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        setKBISDebiteur({url: downloadURL, type: type});
                        //await updateRequete(id, {KBIS_debiteur: {url: downloadURL, type: type}});
                    });
                }
            );
        }
    }
    const uploadFactures = (event, updateFactures) => {
        if (event.target.files.length > 30) {
            alert("Vous ne pouvez pas télécharger plus de 30 factures dans un dossier.")
        } else {
            const uploader = document.getElementById('uploader');
            uploader.className = "opacity-100 container-fluid";
            const storage = getStorage(firebaseApp);
            const files = event.target.files;
            let progress = [];

            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                let type = file.type;
                let extension = "";
                if (type === "image/jpeg") {
                    extension = "jpg";
                } else if (type === "image/png") {
                    extension = "png";
                } else if (type === "application/pdf") {
                    extension = "pdf";
                } else {
                    openModal(document.getElementById("modal_format_fichier"));
                }
                if (extension !== "") {
                    const storageRef = ref(storage, 'factures/' + (Date.now().toString(36) + Math.random().toString(36).substring(2)) + "." + extension);
                    const uploadTask = uploadBytesResumable(storageRef, file);
                    uploadTask.on('state_changed',
                        (snapshot) => {
                            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                            progress[i] = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            let total = progress.reduce((a, b) => a + b, 0) / progress.length;
                            uploader.value = total;
                            switch (snapshot.state) {
                                case 'paused':
                                    break;
                                case 'running':
                                    break;
                                default:

                            }
                        },
                        (error) => {
                            // A full list of error codes is available at
                            // https://firebase.google.com/docs/storage/web/handle-errors
                            switch (error.code) {
                                case 'storage/unauthorized':
                                    // User doesn't have permission to access the object
                                    break;
                                case 'storage/canceled':
                                    // User canceled the upload
                                    break;

                                // ...

                                case 'storage/unknown':
                                    // Unknown error occurred, inspect error.serverResponse
                                    break;
                                default:
                            }
                        },
                        async () => {
                            // Upload completed successfully, now we can get the download URL
                            await getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                                await addFacture(id, downloadURL, type);
                            });
                            if (updateFactures) {
                                let facturesElements = await getFacturesFromRequete(id);
                                setFactures(facturesElements);
                                uploader.className = "opacity-0 container-fluid";
                            } else {
                                setEtape(4);
                                updateRequete(id, {etape: 4})
                            }
                        }
                    );
                }
            }
        }
    }
    const ReadFacture = (facture) => {
        console.log(facture);
        iaFacture(facture).then(async (result) => {

            result = {...result, traite: true}
            console.log(result);
            await updateFacture(facture.facture.id, result);
            setFactures(await getFacturesFromRequete(id));
            setRecalculateTotal(!recalculateTotal);
        });

    }
    const updateAmountFacture = async (idFacture, amount, field) => {
        let update = {};
        update[field] = amount;
        await updateFacture(idFacture, update);
        let facturesUpdate = factures;
        for (let i = 0; i < facturesUpdate.length; i++) {
            if (facturesUpdate[i].id === idFacture) {
                facturesUpdate[i][field] = amount;
            }
        }
        setFactures(facturesUpdate);
        setRecalculateTotal(!recalculateTotal);
    }
    const supprimerFacture = async (factureId) => {
        await deleteFacture(factureId);
        setFactures([]);
        setFactures(await getFacturesFromRequete(id));
        setRecalculateTotal(!recalculateTotal);
    }
    const uploadAccuseReception = (event) => {
        const uploader = document.getElementById('uploader_ar');
        uploader.className = "opacity-100 container-fluid";
        const storage = getStorage(firebaseApp);
        const files = event.target.files;
        let progress = [];
        let file = files[0];
        let type = file.type;
        let extension = "";
        if (type === "image/jpeg") {
            extension = "jpg";
        } else if (type === "image/png") {
            extension = "png";
        } else if (type === "application/pdf") {
            extension = "pdf";
        } else {
            openModal(document.getElementById("modal_format_fichier"));
        }
        /*if (type === "image/webp") {
            extension = "webp";
        }
        if (type === "image/bmp") {
            extension = "bmp";
        }
        if (type === "image/tiff") {
            extension = "tiff";
        }
        if (type === "image/gif") {
            extension = "gif";
        }*/
        if (extension !== "") {
            const storageRef = ref(storage, 'accuseReception/' + (Date.now().toString(36) + Math.random().toString(36).substring(2)) + "." + extension);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    uploader.value = progress;
                    switch (snapshot.state) {
                        case 'paused':
                            break;
                        case 'running':
                            break;
                        default:

                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                        default:
                    }
                },
                async () => {
                    // Upload completed successfully, now we can get the download URL
                    await getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        await addAccuseReception(id, downloadURL, type);
                    });
                }
            );
        }
    }
    const decodeHTMLEntities = (text) => {
        var textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    }
    const uploadMiseEnDemeure = (event) => {
        const uploader = document.getElementById('uploader_med');
        uploader.className = "opacity-100 container-fluid";
        const storage = getStorage(firebaseApp);
        const files = event.target.files;
        let progress = [];
        let file = files[0];
        let type = file.type;
        let extension = "";
        if (type === "image/jpeg") {
            extension = "jpg";
        } else if (type === "image/png") {
            extension = "png";
        } else if (type === "application/pdf") {
            extension = "pdf";
        } else {
            openModal(document.getElementById("modal_format_fichier"));
        }

        /*if (type === "image/webp") {
            extension = "webp";
        }
        if (type === "image/bmp") {
            extension = "bmp";
        }
        if (type === "image/tiff") {
            extension = "tiff";
        }
        if (type === "image/gif") {
            extension = "gif";
        }*/
        if (extension !== "") {
            const storageRef = ref(storage, 'miseEnDemeure/' + (Date.now().toString(36) + Math.random().toString(36).substring(2)) + "." + extension);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    uploader.value = progress;
                    switch (snapshot.state) {
                        case 'paused':
                            break;
                        case 'running':
                            break;
                        default:

                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                        default:
                    }
                },
                async () => {
                    // Upload completed successfully, now we can get the download URL
                    await getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        await addMiseEnDemeure(id, downloadURL, type);
                    });
                }
            );
        }
    }
    const uploadSignature = async (signatureData, endLoading) => {
        const storage = getStorage(firebaseApp);
        const storageRef = ref(storage, 'signatures/' + (Date.now().toString(36) + Math.random().toString(36).substring(2)) + ".png");
        uploadString(storageRef, signatureData, 'data_url').then((snapshot) => {
            getDownloadURL(storageRef).then(async (url) => {
                await updateRequete(id, {signature: url, etape: 10});
                setEtape(10);
                endLoading();
            })
        });

    }
    const uploadSignatureMED = async (signatureData, endLoading) => {
        const storage = getStorage(firebaseApp);
        const storageRef = ref(storage, 'signatures/' + (Date.now().toString(36) + Math.random().toString(36).substring(2)) + ".png");
        uploadString(storageRef, signatureData, 'data_url').then((snapshot) => {
            getDownloadURL(storageRef).then(async (url) => {
                await updateRequete(id, {signatureMED: url, etape: 13});
                setEtape(13);
                endLoading();
            })
        });

    }
    const checkSIREN = async (stopLoading) => {
        let form = document.getElementById("formSociete");
        if (!form.checkValidity()) {

        } else {
            if (SIRENDebiteur === "" || SIRENDebiteur.length !== 9 || isNaN(SIRENDebiteur)) {
                //setSIREN_error(true);
            } else {
                //setSIREN_error(false);
                let ficheIdentite = await getFicheIdentite(SIRENDebiteur);
                setFiche_identite(ficheIdentite);
                if (ficheIdentite?.Data?.EtatRadiation !== "true" && ficheIdentite?.Code !== 404 && SIRENDebiteur) {
                    setSIREN_ok(true);
                    setRaisonSocialeDebiteur(ficheIdentite.Data.Denomination);
                    setAdresseDebiteur(ficheIdentite.Data.Adresse.AdresseConcat);
                    setCodePostalDebiteur(ficheIdentite.Data.Adresse.CodePostal);
                    setVilleDebiteur(ficheIdentite.Data.Adresse.BureauDistributeur);
                    setFormeJuridiqueDebiteur(ficheIdentite.Data.LibelleFormeJuridique);
                } else {
                    setSIREN_ok(false);
                }
            }
        }
        form.classList.add('was-validated');
        stopLoading();
    }
    const submitForm = async (stopLoading) => {
        if (!KBISDebiteur) {
            openModal(document.getElementById("modal_kbis_debiteur"));
            stopLoading();
            return;
        }
        if (!formSociete.current.checkValidity()) {

        } else {
            if (fiche_identite) {
                await updateRequete(id, {
                    SIRENDebiteur: SIRENDebiteur,
                    AdresseDebiteur: adresseDebiteur,
                    CodePostalDebiteur: codePostalDebiteur,
                    VilleDebiteur: villeDebiteur,
                    raisonSocialeDebiteur: RaisonSocialeDebiteur,
                    ficheIdentiteDebiteur: fiche_identite,
                    formeJuridiqueDebiteur: formeJuridiqueDebiteur,
                    NomDebiteur: nomDebiteur,
                    PrenomDebiteur: prenomDebiteur,
                    EmailDebiteur: emailDebiteur,
                    telephoneDebiteur: telephoneDebiteur,
                    etape: 3,
                    KBIS_debiteur: KBISDebiteur
                });
            } else {
                await updateRequete(id, {
                    SIRENDebiteur: SIRENDebiteur,
                    AdresseDebiteur: adresseDebiteur,
                    CodePostalDebiteur: codePostalDebiteur,
                    VilleDebiteur: villeDebiteur,
                    raisonSocialeDebiteur: RaisonSocialeDebiteur,
                    formeJuridiqueDebiteur: formeJuridiqueDebiteur,
                    NomDebiteur: nomDebiteur,
                    PrenomDebiteur: prenomDebiteur,
                    EmailDebiteur: emailDebiteur,
                    telephoneDebiteur: telephoneDebiteur,
                    etape: 3,
                    KBIS_debiteur: KBISDebiteur
                });
            }

        }
        formSociete.current.classList.add('was-validated');
        stopLoading();
    }
    const medUpload = async (endLoading) => {
        const storage = getStorage(firebaseApp);
        let url = requete.miseendemeuregenerate;
        console.log(requete.miseendemeuregenerate);
        let response = await fetch(url);
        let file = await response.blob();
        console.log(file);
        //let progress = [];


        let urlObject = new URL(url);
        let path = urlObject.pathname;
        let filename = path.split("/").pop();
        let extension = filename.split(".").pop();

        console.log(extension);
        let type = "";
        console.log(extension);
        if (extension === "jpg" || extension === "jpeg") {
            type = "image/jpeg";
        } else if (extension === "png") {
            type = "image/png";
        } else if (extension === "pdf") {
            type = "application/pdf";
        } else {
            openModal(document.getElementById("modal_format_fichier"));
            return;
        }


        if (extension !== "") {
            const storageRef = ref(storage, 'miseEnDemeure/' + (Date.now().toString(36) + Math.random().toString(36).substring(2)) + "." + extension);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    //progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    switch (snapshot.state) {
                        case 'paused':
                            break;
                        case 'running':
                            break;
                        default:

                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                        default:
                    }
                },
                async () => {
                    // Upload completed successfully, now we can get the download URL
                    await getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        await addMiseEnDemeure(id, downloadURL, type);
                        endLoading();
                    });
                }
            );
        }


        console.log(requete);
    }
    const arUpload = async () => {
        let envoiRequete = await getIdEnvoiFromRequeteId(id, "requete");
        if (envoiRequete?.result?.envoi_id) {
            let suivi = await getSuiviCourrier(envoiRequete.result.envoi_id);
            setSuiviRequete(suivi);
        }
        const storage = getStorage(firebaseApp);
        let url = urlSite + "/reception/" + suiviRequete?.suiviCourrier?.refTracking;
        console.log(requete.miseendemeuregenerate);
        let response = await fetch(url);
        let file = await response.blob();
        console.log(file);
        //let progress = [];


        let urlObject = new URL(url);
        let path = urlObject.pathname;
        let filename = path.split("/").pop();
        let extension = filename.split(".").pop();
        let type = "application/pdf";
        /*console.log(extension);
        let type = "";
        console.log(extension);
        if (extension === "jpg" || extension === "jpeg") {
            type = "image/jpeg";
        } else if (extension === "png") {
            type = "image/png";
        } else if (extension === "pdf") {
            type = "application/pdf";
        } else {
            openModal(document.getElementById("modal_format_fichier"));
            return;
        }*/


        if (extension !== "") {
            const storageRef = ref(storage, 'miseEnDemeure/' + (Date.now().toString(36) + Math.random().toString(36).substring(2)) + "." + extension);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    //progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    switch (snapshot.state) {
                        case 'paused':
                            break;
                        case 'running':
                            break;
                        default:

                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                        default:
                    }
                },
                async () => {
                    // Upload completed successfully, now we can get the download URL
                    await getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        await addMiseEnDemeure(id, downloadURL, type);
                    });
                }
            );
        }


        console.log(requete);
    }
    const isValidDateFormat = (date) => {
        if (date === undefined || date === null) {
            return false;
        }
        let re = /^\d{4}-\d{2}-\d{2}$/; // Adjusted regex for YYYY-MM-DD

        if (!date.match(re)) {
            return false;
        }

        let parts = date.split("-");
        let year = parseInt(parts[0], 10); // Year is now the first part
        let month = parseInt(parts[1], 10); // Month is the second part
        let day = parseInt(parts[2], 10); // Day is the third part

        if (year < 1000 || year > 3000 || month === 0 || month > 12) {
            return false;
        }

        let monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Adjust for leap years
        if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
            monthLength[1] = 29;
        }

        return day > 0 && day <= monthLength[month - 1];
    };
    const checkFactures = () => {
        let test = true;
        factures.map((element) => {
            if (!isValidDateFormat(element.invoice_date)) {
                test = false;
            }
            if (!element?.invoice_id || element.invoice_id === "") {
                test = false;
            }
            if (!element?.invoice_id || element.invoice_id === "") {
                test = false;
            }
            if (totalTTC - totalPercu <= 0) {
                test = false;
            }
            return element;
        })
        return test;
    }
    const checkSIREN_crediteur = async () => {
        let form = document.getElementById("formSociete");
        if (!form.checkValidity()) {

        } else {
            if (SIREN_crediteur === "" || SIREN_crediteur?.length !== 9 || isNaN(SIREN_crediteur)) {
                //setSIREN_error(true);
            } else {
                //setSIREN_error(false);
                let ficheIdentite_crediteur = await getFicheIdentite(SIREN_crediteur);
                setfiche_identite_crediteur(ficheIdentite_crediteur);
                if (ficheIdentite_crediteur?.Data?.EtatRadiation !== "true" && ficheIdentite_crediteur?.Code !== 404) {
                    setSIREN_ok_crediteur(true);
                    setRaisonSociale_crediteur(ficheIdentite_crediteur.Data.Denomination);
                    setAdresse_crediteur(ficheIdentite_crediteur.Data.Adresse.AdresseConcat);
                    setCodePostal_crediteur(ficheIdentite_crediteur.Data.Adresse.CodePostal);
                    setVille_crediteur(ficheIdentite_crediteur.Data.Adresse.BureauDistributeur);
                } else {
                    setSIREN_ok_crediteur(false);
                }
            }
        }
        form.classList.add('was-validated');
        //stopLoading();
    }
    const changeSIREN_crediteur = async (event) => {
        setSIREN_crediteur(event.target.value);
        await checkSIREN_crediteur();
    }
    const submitForm_crediteur = async (stopLoading) => {
        let form = document.getElementById("formSociete");
        console.log(fiche_identite_crediteur);
        if (!form.checkValidity()) {

        } else {
            let ficheIdentite_crediteur_tmp = await getFicheIdentite(SIREN_crediteur);
            let response = await updateRequete(id, {
                SIREN_crediteur: SIREN_crediteur,
                fiche_identite_crediteur: ficheIdentite_crediteur_tmp,
                RaisonSociale_crediteur: RaisonSociale_crediteur,
                Adresse_crediteur: Adresse_crediteur,
                CodePostal_crediteur: CodePostal_crediteur,
                Ville_crediteur: Ville_crediteur,
                Prenom_crediteur: Prenom_crediteur,
                Nom_crediteur: Nom_crediteur,
                Qualite_crediteur: Qualite_crediteur,
                Telephone_crediteur: Telephone_crediteur,
                Titre_crediteur: Titre_crediteur
            });
            if (response.message === "requete mise a jour") {
                await updateRequete(id, {etape: 1});
                setEtape(1);
            }
        }


        form.classList.add('was-validated');
        stopLoading();
    }

    return (
        <div className={"container-fluid align-items-center justify-content-center d-flex flex-column p-0"}>
            <Helmet>
                <title>Démarrez une demande de paiement sur facturesimpayees.com</title>
                <meta name="description"
                      content="Initiatez une demande de paiement sur facturesimpayees.com pour recevoir rapidement le paiement de vos factures impayées. Utilisez notre formulaire pour soumettre votre requête et suivez l'évolution de votre demande."/>
                <meta name="keywords"
                      content="demande de paiement, factures impayées, plateforme, formulaire, requête, suivi, facturesimpayees.com"/>
            </Helmet>
            {loadingData ?
                <img alt={"Chargement en cours ..."} width={50} src={"../../assets/img/spinner_gris.svg"}/> : <>
                    {etape === 17 ? <div className={"etape0"}>
                        <div className={"texte-gros mb-5 text-center"}>
                            La société créancière est :
                        </div>
                        <div className={"flex-row d-flex justify-content-center gap-4"}>
                            <form className={"form-default needs-validation"} id={"formSociete"} name={"formSociete"}
                                  noValidate={true}>
                                <div className={"row g-3 mb-3"}>
                                    <div className={"col-12"}>
                                        {fiche_identite_crediteur?.Data?.EtatRadiation === "true" ?
                                            <div className={"alert alert-danger mt-2"}>Votre société a été radiée, vous
                                                ne
                                                pouvez
                                                pas utiliser la plateforme avec ce SIREN.</div> : ""}
                                        {fiche_identite_crediteur?.Code === 404 ?
                                            <div className={"alert alert-danger mt-2"}>Votre société n'a pas été
                                                trouvée.</div> : ""}
                                        <input type={"text"} className={"form-control"} id={"SIREN"}
                                               placeholder={"Numéro SIREN"} value={SIREN_crediteur}
                                               onChange={changeSIREN_crediteur}
                                               required pattern={"^[0-9]{9}$"}/>
                                        <div className="invalid-feedback">
                                            Le format du SIREN n'est pas correct.
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-12"}>
                                    <div className={"d-flex flex-column flex-md-row"}>
                                        <Btn
                                            texte={"Valider le SIREN"} iconeDroite={"fleche"}
                                            onClick={async (endLoading) => {
                                                await checkSIREN_crediteur();
                                                endLoading();
                                            }}
                                            loading={true}/>
                                    </div>
                                </div>
                                {SIREN_ok_crediteur ? <div className={"row g-3 mt-3"}>
                                    <div className={"col-12"}>
                                        <input type={"text"} className={"form-control"} id={"RaisonSociale"}
                                               placeholder={"Raison sociale"} value={RaisonSociale_crediteur}
                                               onChange={(e) => {
                                                   setRaisonSociale_crediteur(e.target.value)
                                               }} required/>
                                        <div className="invalid-feedback">
                                            La raison sociale n'est pas correcte.
                                        </div>
                                    </div>
                                    <div className={"col-12"}>
                                        <input type={"text"} className={"form-control"} id={"Adresse"}
                                               placeholder={"Adresse"} value={Adresse_crediteur} onChange={(e) => {
                                            setAdresse_crediteur(e.target.value)
                                        }}
                                               required/>
                                        <div className="invalid-feedback">
                                            L'adresse n'est pas correcte.
                                        </div>
                                    </div>
                                    <div className={"col-12"}>
                                        <input type={"text"} className={"form-control"} id={"CodePostal"}
                                               placeholder={"Code postal"} pattern={"^[0-9]{5}$"}
                                               value={CodePostal_crediteur}
                                               onChange={async (e) => {
                                                   setCodePostal_crediteur(e.target.value);
                                                   let ville = await getVilleFromCodePostal(e.target.value);
                                                   setVille_crediteur(ville)
                                               }} required/>
                                        <div className="invalid-feedback">
                                            Le code postal n'est pas correct.
                                        </div>
                                    </div>
                                    <div className={"col-12"}>
                                        <input type={"text"} className={"form-control"} id={"Ville"}
                                               placeholder={"Ville"} value={Ville_crediteur} onChange={(e) => {
                                            setVille_crediteur(e.target.value)
                                        }} required/>
                                        <div className="invalid-feedback">
                                            La ville n'est pas correcte.
                                        </div>
                                    </div>
                                    <div className={"my-3"}></div>

                                    <div className={"col-12"}>
                                        <select className={"form-control"} id={"Titre"} value={Titre_crediteur}
                                                onChange={(e) => {
                                                    setTitre_crediteur(e.target.value)
                                                }} required>
                                            <option value={""}>-- Selectionnez un titre --</option>
                                            <option value={"Madame"}>Madame</option>
                                            <option value={"Monsieur"}>Monsieur</option>
                                        </select>
                                        <div className="invalid-feedback">
                                            Selectionnez un titre
                                        </div>
                                    </div>
                                    <div className={"col-12"}>
                                        <input type={"text"} className={"form-control"} id={"Prenom"}
                                               placeholder={"Prénom"} value={Prenom_crediteur} onChange={(e) => {
                                            setPrenom_crediteur(e.target.value)
                                        }}
                                               required/>
                                        <div className="invalid-feedback">
                                            Le prénom n'est pas correct.
                                        </div>
                                    </div>
                                    <div className={"col-12"}>
                                        <input type={"text"} className={"form-control"} id={"Nom"}
                                               placeholder={"Nom"} value={Nom_crediteur} onChange={(e) => {
                                            setNom_crediteur(e.target.value)
                                        }} required/>
                                        <div className="invalid-feedback">
                                            Le nom n'est pas correct.
                                        </div>
                                    </div>
                                    <div className={"col-12"}>
                                        <input type={"text"} className={"form-control"} id={"Qualite"}
                                               placeholder={"Qualité"} value={Qualite_crediteur} onChange={(e) => {
                                            setQualite_crediteur(e.target.value)
                                        }}
                                               required/>
                                        <div className="invalid-feedback">
                                            La qualité n'est pas correcte.
                                        </div>
                                    </div>
                                    <div className={"col-12"}>
                                        <input type={"tel"} className={"form-control"} id={"Telephone"}
                                               placeholder={"Téléphone"} value={Telephone_crediteur} onChange={(e) => {
                                            setTelephone_crediteur(e.target.value)
                                        }} required/>
                                        <div className="invalid-feedback">
                                            Le téléphone n'est pas correct.
                                        </div>
                                    </div>
                                    <div className={"col-12"}>
                                        <div className={"d-flex flex-column flex-md-row"}>
                                            <Btn
                                                texte={"Suivant"} iconeDroite={"fleche"} onClick={submitForm_crediteur}
                                                loading={true}/>
                                        </div>
                                    </div>
                                </div> : null}

                                <Modal id={"confirmation"} titre={"Société mise à jour"}
                                       body={"Les informations de votre société ont bien été mise à jour."}>
                                    <Btn texte="OK" onClick={() => {
                                        closeModal(document.getElementById("confirmation"))
                                    }}/>
                                </Modal>
                                <Modal id={"modal_format_fichier"} titre={"Mauvais format"}
                                       body={"Le type du fichier uploadé n'est pas correct, le type doit être : PDF, JPG ou PNG."}>
                                    <Btn texte="Ok" onClick={async () => {
                                        closeModal(document.getElementById("modal_format_fichier"));
                                    }}/>
                                </Modal>
                            </form>
                        </div>
                    </div> : null}
                    {etape === 1 ? <div className={"etape1"}>
                        <div className={"texte-gros mb-5 text-center"}>
                            Votre débiteur est :
                        </div>
                        <div className={"flex-md-row d-flex justify-content-center gap-4 flex-column"}>
                            <Btn texte={"Un particulier"} onClick={isParticulier} loading={true}/>
                            <Btn texte={"Une entreprise"} onClick={isEntreprise} loading={true}/>
                        </div>
                        {SIREN_crediteur ?
                            <div
                                className={"container d-flex justify-content-center align-content-center mt-5 gap-4 pb-2"}>
                                <Btn texte={"Précédent"} iconeGauche={"flecheGauche"} loading={true}
                                     onClick={async () => {
                                         await updateRequete(id, {etape: 17});
                                         setEtape(17);
                                     }}/>
                            </div> : null}
                    </div> : null}
                    {etape === 2 ? <div className={"etape2 container p-0"}>
                        <div className={"texte-gros mb-5 text-center"}>
                            Coordonnées de votre débiteur :
                        </div>
                        <form className={"form-default needs-validation gap-3 d-flex flex-column"}
                              id={"formParticulier"}
                              name={"formParticulier"}
                              noValidate={true}>

                            <div className={"col-12"}>
                                <select className={"form-control"} id={"Titre"} value={titreDebiteur}
                                        onChange={(event) => {
                                            setTitreDebiteur(event.target.value)
                                        }} required>
                                    <option value={""}>-- Selectionnez un titre --</option>
                                    <option value={"Madame"}>Madame</option>
                                    <option value={"Monsieur"}>Monsieur</option>
                                </select>
                                <div className="invalid-feedback">
                                    Selectionnez un titre
                                </div>
                            </div>

                            <div className={"col-12"}>
                                <input type={"text"} className={"form-control"} id={"Prenom"}
                                       placeholder={"Prénoms"} value={prenomDebiteur}
                                       onChange={(event) => {
                                           setPrenomDebiteur(event.target.value)
                                       }} required/>
                                <div className="invalid-feedback">
                                    Le prénom n'est pas correct.
                                </div>
                            </div>

                            <div className={"col-12"}>
                                <input type={"text"} className={"form-control"} id={"Nom"}
                                       placeholder={"Nom de naissance"} value={nomDebiteur}
                                       onChange={(event) => {
                                           setNomDebiteur(event.target.value)
                                       }} required/>
                                <div className="invalid-feedback">
                                    Le nom n'est pas correct.
                                </div>
                            </div>

                            <div className={"col-12"}>
                                <input type={"text"} className={"form-control"} id={"Nom"}
                                       placeholder={"Nom d'époux"} value={nomEpouxDebiteur}
                                       onChange={(event) => {
                                           setNomEpouxDebiteur(event.target.value)
                                       }}/>
                                <div className="invalid-feedback">
                                    Le nom n'est pas correct.
                                </div>
                            </div>

                            <div className={"col-12"}>
                                <input type={"text"} className={"form-control"} id={"Adresse"}
                                       placeholder={"Adresse"} value={adresseDebiteur}
                                       onChange={(event) => {
                                           setAdresseDebiteur(event.target.value)
                                       }} required/>
                                <div className="invalid-feedback">
                                    L'adresse n'est pas correcte.
                                </div>
                            </div>

                            <div className={"col-12"}>
                                <input type={"text"} className={"form-control"} id={"CodePostal"}
                                       placeholder={"Code postal"} pattern={"^[0-9]{5}$"} value={codePostalDebiteur}
                                       onChange={async (event) => {
                                           setCodePostalDebiteur(event.target.value);
                                           let ville = await getVilleFromCodePostal(event.target.value);
                                           setVilleDebiteur(ville);
                                       }} required/>
                                <div className="invalid-feedback">
                                    Le code postal n'est pas correct.
                                </div>
                            </div>

                            <div className={"col-12"}>
                                <input type={"text"} className={"form-control"} id={"Ville"}
                                       placeholder={"Ville"} value={villeDebiteur}
                                       onChange={(event) => {
                                           setVilleDebiteur(event.target.value)
                                       }} required/>
                                <div className="invalid-feedback">
                                    La ville n'est pas correcte.
                                </div>
                            </div>


                            <div className={"col-12"}>
                                <select className={"form-control"} id={"Pays"}
                                        placeholder={"Pays"} value={paysDebiteur}
                                        onChange={(event) => {
                                            setPaysDebiteur(event.target.value)
                                        }} required>
                                    <option value={""}>-- Selectionnez un pays --</option>
                                    <option value={"FR"}>France</option>
                                    <option value={""}>-------------------------</option>
                                    {Object.keys(pays).map((elementPays) => {
                                        return (
                                            <option key={elementPays} value={elementPays}>{pays[elementPays]}</option>)
                                    })}
                                </select>

                                <div className="invalid-feedback">
                                    Le pays n'est pas correcte.
                                </div>
                            </div>

                            <div className={"col-12"}>
                                <input type={"email"} className={"form-control"} id={"Email"}
                                       placeholder={"Email"} value={emailDebiteur}
                                       onChange={(event) => {
                                           setEmailDebiteur(event.target.value)
                                       }}/>
                                <div className="invalid-feedback">
                                    L'email n'est pas correcte.
                                </div>
                            </div>

                        </form>
                        <div
                            className={"container d-flex justify-content-center align-content-center mt-5 gap-4  pb-2"}>
                            <Btn texte={"Précédent"} iconeGauche={"flecheGauche"} loading={true} onClick={() => {
                                setEtape(1)
                            }}/>
                            <Btn texte={"Suivant"} iconeDroite={"fleche"} loading={true} onClick={updateDebiteur}/>
                        </div>
                        <Modal id={"pays_error"} titre={"Le débiteur n'est pas en France."}
                               body={<div>Votre débiteur doit être en France.</div>}>
                            <Btn texte={"OK"} onClick={async () => {
                                closeModal(document.getElementById("pays_error"));
                            }}/>
                        </Modal>
                    </div> : null}
                    {etape === 3 ? <div className={"etape3 container p-0"}>
                        <div className={"texte-gros mb-5 text-center"}>
                            Importez votre ou toutes vos facture(s) impayée(s) pour ce débiteur
                        </div>
                        <div
                            className={"d-flex gap-5 justify-content-center align-items-center flex-column flex-md-row"}>
                            <div className={"d-flex flex-column"}>
                                <div className={"mb-4"}>Depuis votre ordinateur (fichier)</div>
                                <Btn texte={"Charger"} iconeDroite={"upload"} onClick={() => {
                                    document.getElementById("factures").click()
                                }}/>
                                <progress id="uploader" className={"opacity-0 container-fluid"} value="0"
                                          max="100"></progress>
                                <div
                                    className={""}>{factures.length ? factures.length + " facture(s) déjà uploadée(s)" : null}</div>
                            </div>
                            <input type="file" id="factures" className={"d-none"} multiple={true}
                                   onChange={uploadFactures}/>
                            <div className={"d-flex align-content-center align-items-center mx-5"}>ou</div>
                            <div className={"d-flex justify-content-center align-items-center flex-column"}>
                                <div className={"mb-4"}>Depuis votre téléphone</div>
                                <QRCode value={urlSite + "/upload/" + id}/>
                                <div className={"text-center mt-3"}>
                                    Scanner ce QR Code et suivez la procédure
                                </div>
                            </div>
                        </div>
                        <div
                            className={"container d-flex justify-content-center align-content-center mt-5 gap-4  pb-2"}>
                            <Btn texte={"Précédent"} iconeGauche={"flecheGauche"} loading={true} onClick={() => {
                                if (requete.type === "entreprise") {
                                    updateRequete(id, {etape: 12});
                                    setEtape(12);
                                } else {
                                    updateRequete(id, {etape: 2});
                                    setEtape(2);
                                }

                            }}/>
                            {<Btn texte={"Suivant"} iconeDroite={"fleche"} loading={true} onClick={async () => {
                                setEtape(4);
                                await updateRequete(id, {etape: 4});
                            }}/>}
                        </div>
                    </div> : null}
                    {etape === 4 ? <div className={"etape4 container px-0"}>
                        <div className={"texte-gros mb-5 text-center"}>
                            Vérifiez le montant de vos factures
                        </div>
                        <div className={"mb-5 text-center"}>
                            Les montants sont reconnus automatiquement ; veillez à les vérifier, car ils ne sont pas
                            toujours
                            lisibles.<br/>
                            Complétez le cas échéant les montants partiels que vous auriez déjà perçus
                        </div>


                        <div className={"container-fluid px-0"}>
                            {factures.length ? factures.map((element, index) => {
                                return (<div key={index} className={"bloc-facture pb-5 d-flex flex-column flex-xl-row"}>


                                    <div
                                        className={"image-facture col-xl-4 col-lg-12 d-flex justify-content-center" + (!element.traite ? "loading-facture" : "")}
                                        onClick={() => {
                                            window.open(element.url);
                                        }}>
                                        {element.type === "application/pdf" ?
                                            <PDFViewer src={element.url} width={400} className={"page-pdf"}/> :
                                            <img alt={"Facture"} src={element.url} width={400} className={"page-pdf"}/>}
                                    </div>


                                    <div
                                        className={"col-xl-8 col-lg-12 d-flex justify-content-center align-items-center flex-column"}>
                                        {element.traite ? <form className={"container-fluid px-0"}>
                                            <div className={"row my-3"}>
                                                <label
                                                    className={"col-6 label d-flex justify-content-end align-items-center"}
                                                    htmlFor={"totalHT_" + element.id}>
                                                    Total HT
                                                </label>
                                                <div className={"col-6"}>
                                                    <input type={"number"} name={"totalHT_" + element.id}
                                                           id={"totalHT_" + element.id}
                                                           className={"tarif-champs"} defaultValue={element.net_amount}
                                                           onChange={async (event) => {
                                                               await updateAmountFacture(element.id, safeParseFloat(event.target.value), "net_amount");
                                                               await updateAmountFacture(element.id, safeParseFloat(event.target.value) + safeParseFloat(element.total_tax_amount), "total_amount");
                                                           }}/>
                                                    <span className={"label ps-2"}>€</span>
                                                </div>
                                            </div>

                                            <div className={"row my-3"}>
                                                <label
                                                    className={"col-6 label d-flex justify-content-end align-items-center"}
                                                    htmlFor={"tva_" + element.id}>
                                                    TVA
                                                </label>
                                                <div className={"col-6"}>
                                                    <input type={"number"} name={"tva_" + element.id}
                                                           id={"tva_" + element.id}
                                                           className={"tarif-champs"}
                                                           defaultValue={element.total_tax_amount}
                                                           onChange={async (event) => {
                                                               await updateAmountFacture(element.id, safeParseFloat(event.target.value), "total_tax_amount");
                                                               await updateAmountFacture(element.id, safeParseFloat(event.target.value) + safeParseFloat(element.net_amount), "total_amount");
                                                           }}/>
                                                    <span className={"label ps-2"}>€</span>
                                                </div>
                                            </div>

                                            <div className={"row my-3"}>
                                                <label
                                                    className={"col-6 label d-flex justify-content-end align-items-center"}
                                                    htmlFor={"totalTTC_" + element.id}>
                                                    Total TTC
                                                </label>
                                                <div className={"col-6"}>
                                                    <input disabled={true} type={"number"}
                                                           name={"totalTTC_" + element.id}
                                                           id={"totalTTC_" + element.id}
                                                           className={"tarif-champs"} value={element.total_amount}
                                                        /*onChange={async (event) => {
                                                            await updateAmountFacture(element.id, event.target.value, "total_amount");
                                                        }}*//>
                                                    <span className={"label ps-2"}>€</span>
                                                </div>
                                            </div>

                                            <div className={"row my-3"}>
                                                <label
                                                    className={"col-6 label d-flex justify-content-end align-items-center"}
                                                    htmlFor={"totalPercu_" + element.id}>
                                                    Total perçu
                                                </label>
                                                <div className={"col-6"}>
                                                    <input type={"number"} name={"totalPercu_" + element.id}
                                                           id={"totalPercu_" + element.id}
                                                           className={"tarif-champs"}
                                                           defaultValue={element.total_received}
                                                           onChange={async (event) => {
                                                               await updateAmountFacture(element.id, event.target.value, "total_received");
                                                           }}/>
                                                    <span className={"label ps-2"}>€</span>
                                                </div>
                                            </div>

                                            <div className={"row my-3"}>
                                                <label
                                                    className={"col-6 label d-flex justify-content-end align-items-center"}
                                                    htmlFor={"totalPercu_" + element.id}>
                                                    Date
                                                </label>
                                                <div className={"col-6"}>
                                                    <input type={"date"} name={"date_" + element.id}
                                                           id={"date_" + element.id}
                                                           className={"tarif-champs"}
                                                           defaultValue={element.invoice_date}
                                                           onChange={async (event) => {
                                                               await updateAmountFacture(element.id, event.target.value, "invoice_date");
                                                           }}/>
                                                </div>
                                            </div>

                                            <div className={"row my-3"}>
                                                <label
                                                    className={"col-6 label d-flex justify-content-end align-items-center"}
                                                    htmlFor={"totalPercu_" + element.id}>
                                                    Numéro de facture
                                                </label>
                                                <div className={"col-6"}>
                                                    <input type={"text"} name={"numero_" + element.id}
                                                           id={"numero_" + element.id}
                                                           className={"tarif-champs"} defaultValue={element.invoice_id}
                                                           onChange={async (event) => {
                                                               await updateAmountFacture(element.id, event.target.value, "invoice_id");
                                                           }}/>
                                                </div>
                                            </div>


                                        </form> : <div
                                            className={"en-cours-facture d-flex justify-content-center align-items-center flex-column"}>
                                            <img src={"../../assets/img/balayage.gif"} alt={"IA"} width={100}/>
                                            <div className={"texte-standard"}>Facture en cours de traitement ...</div>
                                            <ReadFacture facture={element}/>
                                        </div>}
                                        <div>
                                            <Btn texte={"Supprimer"} className={"mt-5"} onClick={() => {
                                                openModal(document.getElementById("modal_facture_supprime_" + element.id))
                                            }}/>
                                        </div>
                                    </div>
                                    <Modal id={"modal_facture_supprime_" + element.id}
                                           titre={"Suppression d'une facture"}
                                           body={"Êtes vous sur de vouloir supprimer cette facture ?"}>
                                        <Btn texte="Oui" onClick={async () => {
                                            closeModal(document.getElementById("modal_facture_supprime_" + element.id));
                                            supprimerFacture(element.id);
                                        }}/>
                                        <Btn texte="Annuler" onClick={async () => {
                                            closeModal(document.getElementById("modal_facture_supprime_" + element.id));
                                        }}/>
                                    </Modal>
                                </div>)
                            }) : null}
                        </div>


                        <div
                            className={"container-fluid d-flex justify-content-center align-items-center flex-column col-12  px-0"}>
                            {factures.length < 30 || factures.length === undefined ?
                                <Btn texte={"Ajouter une facture"} iconeGauche={"plus"} onClick={() => {
                                    document.getElementById("factures").click()
                                }}/> : null}
                            <progress id="uploader" className={"opacity-0 container-fluid"} value="0"
                                      max="100"></progress>
                            <input type="file" id="factures" className={"d-none"}
                                   accept="image/jpeg, image/png, application/pdf" multiple={true}
                                   onChange={uploadNewFactures}/>
                        </div>
                        <div className={"container-fluid d-flex justify-content-center align-items-center px-0"}>
                            <form className={"container-fluid mt-5 px-0"}>
                                <div className={"row my-3"}>
                                    <label className={"col-6 label d-flex justify-content-end align-items-center"}
                                           htmlFor={"totalHT"}>
                                        Total HT
                                    </label>
                                    <div className={"col-6"}>
                                        <input type={"number"} name={"totalHT"} id={"totalHT"}
                                               className={"tarif-champs"}
                                               value={totalHT} disabled={true}/>
                                        <span className={"label ps-2"}>€</span>
                                    </div>
                                </div>

                                <div className={"row my-3"}>
                                    <label className={"col-6 label d-flex justify-content-end align-items-center"}
                                           htmlFor={"tva"}>
                                        TVA
                                    </label>
                                    <div className={"col-6"}>
                                        <input type={"number"} name={"tva"} id={"tva"}
                                               className={"tarif-champs"} value={totalTVA} disabled={true}/>
                                        <span className={"label ps-2"}>€</span>
                                    </div>
                                </div>

                                <div className={"row my-3"}>
                                    <label className={"col-6 label d-flex justify-content-end align-items-center"}
                                           htmlFor={"totalTTC"}>
                                        Total TTC
                                    </label>
                                    <div className={"col-6"}>
                                        <input type={"number"} name={"totalTTC"} id={"totalTTC"}
                                               className={"tarif-champs"} value={totalTTC} disabled={true}/>
                                        <span className={"label ps-2"}>€</span>
                                    </div>
                                </div>

                                <div className={"row my-3"}>
                                    <label className={"col-6 label d-flex justify-content-end align-items-center"}
                                           htmlFor={"totalPercu"}>
                                        Total perçu
                                    </label>
                                    <div className={"col-6"}>
                                        <input type={"number"} name={"totalPercu"} id={"totalPercu"}
                                               className={"tarif-champs"} value={totalPercu} disabled={true}/>
                                        <span className={"label ps-2"}>€</span>
                                    </div>
                                </div>
                                <div className={"row my-3"}>
                                    <label className={"col-6 label d-flex justify-content-end align-items-end text-end"}
                                           htmlFor={"totalPercu"}>
                                        Total restant à payer
                                    </label>
                                    <div className={"col-6"}>
                                        <input type={"number"} name={"totalPercu"} id={"totalPercu"}
                                               className={"tarif-champs"} value={totalTTC - totalPercu}
                                               disabled={true}/>
                                        <span className={"label ps-2"}>€</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div
                            className={"container d-flex justify-content-center align-content-center mt-5 gap-4  pb-2  px-0"}>
                            <Btn texte={"Précédent"} iconeGauche={"flecheGauche"} loading={true} onClick={async () => {
                                setEtape(3);
                                await updateRequete(id, {etape: 3});
                            }}/>
                            {factures.length ? <Btn texte={"Suivant"} iconeDroite={"fleche"} onClick={async () => {
                                if (checkFactures()) {
                                    openModal(document.getElementById("modal_confirmation_facture"));
                                } else {
                                    openModal(document.getElementById("modal_erreur_factures"));
                                }
                            }}/> : null}
                            <Modal id={"modal_confirmation_facture"} titre={"Confirmation factures"}
                                   body={"Avez-vous bien saisi toutes vos factures impayées pour ce débiteur et vérifié les dates et montants correspondants ? Soyez vigilant, car une fois la validation effectuée, il ne vous sera plus possible de revenir en arrière."}>
                                <Btn texte="Oui" onClick={async () => {
                                    closeModal(document.getElementById("modal_confirmation_facture"));
                                    setEtape(5);
                                    await updateRequete(id, {etape: 5});
                                }}/>
                                <Btn texte="Non" onClick={async () => {
                                    closeModal(document.getElementById("modal_confirmation_facture"));
                                }}/>
                            </Modal>

                            <Modal id={"modal_erreur_factures"} titre={"Factures"}
                                   body={"Le montant total restant à payer est inférieur ou égal à zéro, ou bien les numéros et dates des factures n'ont pas été saisis. Veuillez vérifier."}>
                                <Btn texte="Ok" onClick={async () => {
                                    closeModal(document.getElementById("modal_erreur_factures"));
                                }}/>
                            </Modal>
                        </div>
                    </div> : null}
                    {etape === 5 ? <div className={"etape5"}>
                        <div className={"texte-gros mb-5 text-center"}>
                            Avez-vous déjà envoyé une relance pour impayé en recommandé avec accusé de réception à votre
                            débiteur ?
                        </div>
                        <div className={"texte-standard mb-5 text-center"}>
                            L’envoi d’une relance (mise en demeure de payer) est obligatoire
                            pour finaliser votre dossier
                        </div>
                        <div
                            className={"container d-flex justify-content-center align-content-center mt-5 gap-4  pb-2"}>
                            <Btn texte={"Oui"} className={"px-5"} onClick={async () => {
                                setEtape(6);
                                await updateRequete(id, {etape: 6});
                            }}/>
                            <div className={"d-flex justify-content-center align-content-center"}>
                                <Btn texte={"Non"} className={"px-5"} onClick={async () => {
                                    setEtape(15);
                                    await updateRequete(id, {etape: 15});
                                }}/>
                                <div className={"position-absolute text-center mt-5 pt-1 texte-standard small"}>Nous en
                                    créons
                                    une<br/> pour vous.
                                </div>
                            </div>
                        </div>
                        <div className={"d-flex justify-content-center mt-15"}>
                            <Btn texte={"Télécharger une simple relance"} className={"px-5"} onClick={async () => {
                                window.open(urlSite + "/relance/" + id);
                            }}/>
                        </div>
                        <div
                            className={"container d-flex justify-content-center align-content-center mt-5 pt-5 gap-4 mb-3"}>

                            <Btn texte={"Précédent"} iconeGauche={"flecheGauche"} loading={true} onClick={async () => {
                                setEtape(4);
                                await updateRequete(id, {etape: 4});
                            }}/>
                        </div>
                    </div> : null}
                    {etape === 6 ? <div className={"etape3 container"}>
                        <div className={"texte-gros mb-5 text-center"}>
                            Télécharger la mise en demeure et l'accusé de reception
                        </div>
                        <div
                            className={"d-flex gap-5 justify-content-center align-items-center flex-column flex-md-row"}>
                            <div className={"d-flex flex-column justify-content-center align-items-center"}>
                                <div className={"d-flex flex-column"}>
                                    <div className={"text-center mb-2"}>Télécharger une copie de la mise en demeure que
                                        vous avez envoyée à votre débiteur.
                                    </div>
                                    {requete.miseendemeuregenerate ? <Btn className={"mb-2"}
                                                                          texte={"Utiliser la mise en demeure générée par facturesimpayees.com"}
                                                                          loading={true}
                                                                          onClick={async (endLoading) => {
                                                                              await medUpload(endLoading);
                                                                          }}/> : null}
                                    <div className={"d-flex justify-content-center"}>ou</div>
                                    <Btn texte={"Charger les documents"} iconeDroite={"upload"} onClick={() => {
                                        document.getElementById("med").click()
                                    }}/>
                                    <progress id="uploader_med" className={"opacity-0 container-fluid"} value="0"
                                              max="100"></progress>
                                    {requete.miseEnDemeure ?
                                        <div
                                            className={"d-flex flex-row justify-content-center align-items-center mb-5 mt-2"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                 fill="currentColor"
                                                 className="bi bi-file-check" viewBox="0 0 16 16">
                                                <path
                                                    d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                                <path
                                                    d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                                            </svg>
                                            Document téléchargé :  &nbsp;<a className={"violet"} rel="noreferrer"
                                                                            target={"_blank"}
                                                                            href={requete.miseEnDemeure.url}>Mise
                                            en demeure</a></div> : null}
                                    <input type="file" id="med" className={"d-none"} onChange={uploadMiseEnDemeure}/>
                                </div>

                                <div className={"d-flex flex-column"}>
                                    <div className={"mb-1 text-center mb-2"}>Télécharger l'accusé de reception de votre
                                        recommandé.
                                    </div>
                                    {suiviRequete?.suiviCourrier?.refTracking ? <Btn className={"mb-2"} loading={true}
                                                                                     texte={"Utiliser l'AR receptionné par facturesimpayees.com"}
                                                                                     onClick={async (endLoading) => {
                                                                                         await arUpload();
                                                                                         endLoading();
                                                                                     }}/> : null}
                                    <Btn texte={"Charger les documents"} iconeDroite={"upload"} onClick={() => {
                                        document.getElementById("ar").click()
                                    }}/>
                                    <progress id="uploader_ar" className={"opacity-0 container-fluid"} value="0"
                                              max="100"></progress>
                                    {requete.accuseReception ?
                                        <div
                                            className={"d-flex flex-row justify-content-center align-items-center mb-5 mt-2"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                 fill="currentColor"
                                                 className="bi bi-file-check" viewBox="0 0 16 16">
                                                <path
                                                    d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                                <path
                                                    d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                                            </svg>
                                            Document téléchargé :  &nbsp;<a className={"violet"} rel="noreferrer"
                                                                            target={"_blank"}
                                                                            href={requete.accuseReception.url}>Accusé de
                                            reception</a></div> : null}
                                    <input type="file" id="ar" className={"d-none"} onChange={uploadAccuseReception}/>
                                </div>
                            </div>
                            {/*<div className={"d-flex align-content-center align-items-center mx-5"}>ou</div>
                    <div className={"d-flex justify-content-center align-items-center flex-column"}>
                        <img width={150}
                             src={"https://upload.wikimedia.org/wikipedia/commons/7/78/Qrcode_wikipedia_fr_v2clean.png"}
                             alt={"QR Code"}/>
                        <div className={"text-center mt-3"}>
                            Uploadez vos documents<br/> depuis votre téléphone
                        </div>
                    </div>*/}
                        </div>
                        <Modal id={"modal_mise_en_demeure"} titre={"Avez-vous vérifié ?"}
                               body={<div>
                                   <ul>
                                       <li>Que la mise en demeure comporte l’indication des factures échues et non
                                           payées
                                           intégralement ou partiellement (N°, date, montant).
                                       </li>
                                       <li>Qu'elle contienne un délai de paiement précis.
                                       </li>
                                       <li>Et que le délais imparti pour payer par votre courrier de mise en demeure est
                                           échu.
                                       </li>
                                   </ul>
                               </div>}>
                            <Btn texte="Oui" onClick={async () => {
                                closeModal(document.getElementById("modal_mise_en_demeure"));
                                await updateRequete(id, {etape: 8});
                                setEtape(8);
                            }}/>
                            <Btn texte="Non" onClick={async () => {
                                closeModal(document.getElementById("modal_mise_en_demeure"));
                            }}/>
                        </Modal>
                        <div
                            className={"container d-flex justify-content-center align-content-center mt-5 gap-4  pb-2"}>
                            <Btn texte={"Précédent"} iconeGauche={"flecheGauche"} loading={true}
                                 onClick={async (endLoading) => {
                                     await updateRequete(id, {etape: 5});
                                     setEtape(5);
                                     endLoading();
                                 }}/>
                            {requete.miseEnDemeure && requete.accuseReception ?
                                <Btn texte={"Suivant"} iconeDroite={"fleche"} onClick={async () => {
                                    openModal(document.getElementById("modal_mise_en_demeure"));
                                }}/> : null}
                        </div>
                    </div> : null}
                    {etape === 7 ?
                        <div className={"etape7 d-flex justify-content-center align-items-center flex-column"}>
                            <div className={"texte-gros mb-5 text-center"}>
                                Prévisualiser la requête de demande d'ordonnance de payer et finaliser mon dossier
                            </div>
                            <Btn className={"mb-5"} texte={"Prévisualiser mon dossier"} iconeGauche={"download"}
                                 loading={true}
                                 onClick={async (endLoading) => {
                                     await updateRequete(id, {etape: 8});
                                     setEtape(8);
                                     endLoading();
                                 }}/>
                            {/*<div className={"texte-gros mb-5 text-center"}>
                    Votre courrier est expédié en recommandé avec<br/>
                    accusé de réception automatiquement par la plateforme<br/>
                    au tribunal compétant<br/>
                    <br/>
                    Vous pourrez suivre le statut du recommandé en ligne<br/>
                    <br/>
                    Vous recevrez ensuite votre ordonnance de payer directement par la poste
                </div>*/}
                            <div
                                className={"container d-flex justify-content-center align-content-center mt-5 gap-4  pb-2"}>
                                <Btn texte={"Précédent"} iconeGauche={"flecheGauche"} loading={true}
                                     onClick={async (endLoading) => {
                                         await updateRequete(id, {etape: 6});
                                         setEtape(6);
                                         endLoading();
                                     }}/>
                            </div>
                        </div> : null}
                    {etape === 8 ?
                        <div className={"etape8 d-flex justify-content-center align-items-center flex-column"}>
                            <div className={"texte-gros mb-5 text-center"}>
                                Conformité du dossier
                            </div>
                            <iframe title={"Requete"} className={"d-none"} src={"/demande-injonction/" + id}></iframe>
                            <Btn className={"mb-5"} texte={"Prévisualiser la requête"} iconeGauche={"download"}
                                 loading={false}
                                 onClick={async (endLoading) => {
                                     window.lintrk('track', { conversion_id: 20021274 });
                                     console.log("Submit linkedin conversion");
                                     window.open("/demande-injonction/" + id);
                                 }}/>
                            <div className={"texte-gros mb-5"}>
                                Vérifier que le dossier est complet et qu’il ne comporte pas d’erreur :<br/>
                                <br/>
                                <strong>Un dossier complet comprend :</strong><br/>
                                <ul>
                                    <li>Le formulaire cerfa (requête) complété, daté et signé</li>
                                    <li>La ou les factures impayées</li>
                                    <li>La (ou éventuellement les) mise(s) en demeure</li>
                                    <li>L’accusé de réception de la (ou des) mise(s) en demeure</li>
                                </ul>
                                <br/>
                                <strong>Un dossier correct mentionne :</strong><br/>
                                <ul>
                                    {requete.type === "entreprise" ? <li>Le bon n° rcs du débiteur</li> :
                                        <li>L'identité du débiteur</li>}
                                    <li>La bonne adresse du débiteur</li>
                                    <li>Le montant global de l’impayé</li>
                                </ul>
                            </div>
                            {/*<Btn className={"mb-5"} texte={"Corriger"} iconeGauche={"flecheGauche"} loading={true}
                     onClick={async (endLoading) => {
                         if (requete.type === "entreprise") {
                             updateRequete(id, {etape: 12});
                             setEtape(12);
                         } else {
                             updateRequete(id, {etape: 2});
                             setEtape(2);
                         }
                         endLoading();
                     }}/>*/}
                            <Btn className={"mb-5"} texte={"Signer mon dossier"} iconeGauche={"signer"} loading={true}
                                 onClick={async (endLoading) => {
                                     await updateRequete(id, {etape: 9});
                                     setEtape(9);
                                     endLoading();
                                 }}/>
                            <div
                                className={"container d-flex justify-content-center align-content-center mt-5 gap-4  pb-2"}>
                                <Btn texte={"Précédent"} iconeGauche={"flecheGauche"} loading={true}
                                     onClick={async (endLoading) => {
                                         await updateRequete(id, {etape: 6});
                                         setEtape(6);
                                         endLoading();
                                     }}/>
                            </div>
                        </div> : null}
                    {etape === 9 ? <div
                        className={"etape8 d-flex justify-content-center align-items-center flex-column container-fluid"}>
                        <div className={"texte-gros mb-5 text-center"}>
                            Signez la requête à l'aide de votre souris ou votre pavé tactile :
                        </div>
                        <Btn className={"mb-5"} texte={"Prévisualiser la requête"} iconeGauche={"download"}
                             loading={false}
                             onClick={async () => {
                                 window.lintrk('track', { conversion_id: 20021274 });
                                 console.log("Submit linkedin conversion");
                                 window.open("/demande-injonction/" + id);
                                 setCanSignRequete(true);
                             }}/>
                        {canSignRequete ? <div className={"signature container-fluid"} id={"signature"}>
                            <SignaturePad ref={signature} options={{minWidth: 2, maxWidth: 5, penColor: 'rgb(0,0,0)'}}/>
                        </div> : null}
                        {canSignRequete ? <div
                            className={"container-fluid d-flex  flex-column flex-md-row justify-content-center align-content-center gap-2 mt-3"}>
                            <Btn texte={"Effacer"} loading={true} onClick={async (endLoading) => {
                                signature.current.clear();
                                endLoading();
                            }}/>
                            <Btn texte={"Confirmer ma signature"} onClick={async () => {
                                if (signature.current.isEmpty()) {
                                    alert("Vous devez signer avant de continuer.")
                                } else {
                                    openModal(document.getElementById("avertissement2"));
                                }
                            }}/>

                            <Modal id={"avertissement2"} titre={"Avertissement"}
                                   body={"Une fois passé cette étape vous ne pourrez plus revenir en arrière pour modifier vos informations. Êtes vous sur de vouloir continuer ?"}>
                                <Btn texte="Oui" loading={true} onClick={async (endLoading) => {
                                    closeModal(document.getElementById("avertissement2"))
                                    await uploadSignature(signature.current.toDataURL(), endLoading);
                                }}/>
                                <Btn texte="Non" onClick={() => {
                                    closeModal(document.getElementById("avertissement2"))
                                }}/>
                            </Modal>


                        </div> : null}
                        <div
                            className={"container d-flex justify-content-center align-content-center mt-5 gap-4  pb-2"}>
                            <Btn texte={"Précédent"} iconeGauche={"flecheGauche"} loading={true}
                                 onClick={async (endLoading) => {
                                     await updateRequete(id, {etape: 7});
                                     setEtape(7);
                                     endLoading();
                                 }}/>
                        </div>
                    </div> : null}
                    {etape === 15 ? <div
                        className={"etape15 d-flex justify-content-center align-items-center flex-column container-fluid"}>
                        <div className={"texte-gros mb-5 text-center"}>
                            Signez la mise en demeure à l'aide de votre souris ou votre pavé tactile :
                        </div>

                        <Btn className={"mb-5"} texte={"Prévisualiser la mise en demeure"} iconeGauche={"download"}
                             loading={false}
                             onClick={async (endLoading) => {
                                 window.lintrk('track', { conversion_id: 20021266 });
                                 console.log("Submit linkedin conversion");
                                 window.open("/mise-en-demeure/" + id);
                                 setCanSignMed(true);
                             }}/>
                        {canSignMed ? <div className={"signature container-fluid"} id={"signature"}>
                            <SignaturePad ref={signature} options={{minWidth: 2, maxWidth: 5, penColor: 'rgb(0,0,0)'}}/>
                        </div> : null}
                        {canSignMed ? <div
                            className={"container-fluid d-flex flex-column flex-md-row justify-content-center align-content-center gap-2 mt-3"}>
                            <Btn texte={"Effacer"} loading={true} onClick={async (endLoading) => {
                                signature.current.clear();
                                endLoading();
                            }}/>
                            <Btn texte={"Confirmer ma signature !"} onClick={async () => {
                                if (signature.current.isEmpty()) {
                                    alert("Vous devez signer la mise en demeure avant de continuer.")
                                } else {
                                    openModal(document.getElementById("avertissement"));
                                }
                            }}/>
                            <Modal id={"avertissement"} titre={"Avertissement"}
                                   body={"Une fois passé cette étape vous ne pourrez plus modifier vos factures et l'adresse du débiteur. Êtes vous sur de vouloir continuer ?"}>
                                <Btn texte="Oui" loading={true} onClick={async (endLoading) => {
                                    closeModal(document.getElementById("avertissement"))
                                    await uploadSignatureMED(signature.current.toDataURL(), endLoading);
                                }}/>
                                <Btn texte="Non" onClick={() => {
                                    closeModal(document.getElementById("avertissement"))
                                }}/>
                            </Modal>
                        </div> : null}

                        <div
                            className={"container d-flex justify-content-center align-content-center mt-5 gap-4  pb-2"}>
                            <Btn texte={"Précédent"} iconeGauche={"flecheGauche"} loading={true}
                                 onClick={async (endLoading) => {
                                     await updateRequete(id, {etape: 5});
                                     setEtape(5);
                                     endLoading();
                                 }}/>
                        </div>
                    </div> : null}
                    {etape === 16 ? <div className={"etape16 d-flex flex-column container-fluid"}>
                        <div className={"texte-gros mb-5 fw-bold text-center"}>
                            Téléchargez votre Injonction de Payer
                        </div>
                        <div>
                            <p>Pour transmettre votre injonction de payer directement à un commissaire de justice,
                                veuillez
                                télécharger le document reçu du tribunal.</p>
                            <p>Une fois le document téléchargé, un huissier vous contactera dans les plus brefs délais
                                avec
                                une proposition pour la mise en recouvrement de votre dossier.</p>
                            <p>En téléchargeant ce document, vous acceptez que vos informations soient transmises à un
                                commissaire de justice afin qu'il puisse prendre contact avec vous.</p>
                        </div>
                        <progress id="ordonnance_progress" className={"opacity-0 container-fluid"} value="0"
                                  max="100"></progress>
                        <Btn className={"mb-5"} texte={"Télécharger l'ordonnance du tribunal"} iconeGauche={"download"}
                             loading={false} onClick={() => {
                            document.getElementById("ordonnance").click()
                        }}/>
                        <input type="file" id="ordonnance" className={"d-none"} multiple={false}
                               onChange={uploadOrdonnance}/>
                        {ordonnance ?
                            <div className={"d-flex flex-column justify-content-center align-items-center mb-5 mt-2"}>
                                <a className={"violet"} rel="noreferrer" target={"_blank"} href={ordonnance.url}>Voir
                                    mon
                                    ordonnance en ligne</a>
                                <div><p>Un commissaire de justice va vous contacter dans les plus bref délais pour
                                    prendre
                                    en charge le recouvrement de votre dossier.</p></div>
                            </div> : null}

                        <div
                            className={"container d-flex justify-content-center align-content-center mt-5 gap-4  pb-2"}>
                            <Btn texte={"Précédent"} iconeGauche={"flecheGauche"} loading={true} onClick={() => {
                                updateRequete(id, {etape: 10});
                                setEtape(10)
                            }}/>
                        </div>

                    </div> : null}
                    {etape === 10 ?
                        <div className={"etape8 d-flex justify-content-center align-items-center flex-column"}>
                            <div className={"texte-gros mb-5 text-center"}>
                                J'ai finalisé mon dossier. J'envoie ma requête en injonction de payer.
                            </div>
                            <Btn className={"mb-5"} texte={"Télécharger la requête définitive"} iconeGauche={"download"}
                                 loading={false}
                                 onClick={async (endLoading) => {
                                     window.open("/demande-injonction/" + id);
                                 }}/>

                            {requete.type === "entreprise" && !isSocieteCivileType ? <div>
                                    <div className={"texte-gros mb-5 text-center"}>
                                        Envoyez la requête à l'adresse en lettre recommandée avec accusé de réception
                                        :<br/> {tj.nom}
                                        <br/>
                                        Service des requêtes en injonctions de payer<br/>
                                        {loadingTJ ?
                                            <Alert variant={"info"} className={"mb-5 text-center mt-5"}>Recherche du
                                                tribunal compétent
                                                ...</Alert> : <>
                                                {tj.adresse ? "" :
                                                    <Alert className={"mt-2"} variant={"danger"}>Nous n'avons pas trouvé
                                                        l'adresse du
                                                        tribunal compétent.<br/>Complétez manuellement l'adresse dans le
                                                        document.</Alert>}
                                                {tj.adresse ? tj.adresse : null}<br/>
                                                {tj.codepostal} {tj.ville}<br/><br/>
                                                avec un chèque joint d'une valeur de 33,47 euros<br/> de frais de greffe à
                                                l'ordre
                                                du {tj.nom ? tj.nom : "tribunal du commerce de votre ville."}.
                                            </>}
                                    </div>
                                    <div className={"d-flex justify-content-center flex-column gap-2"}>
                                        {/*<Btn texte={"Transmettre mon dossier à un commissaire de justice"} loading={true}
                                 onClick={async (endLoading) => {
                                     setEtape(16);
                                     await updateRequete(id, {etape: 16});
                                     endLoading();
                                 }}/>*/}

                                        <Btn texte={"Retour à la liste des requêtes"} iconeGauche={"flecheGauche"}
                                             loading={true}
                                             onClick={async (endLoading) => {
                                                 document.location.href = "/dashboard/requete-en-cours"
                                                 endLoading();
                                             }}/>
                                    </div>
                                </div> :
                                (tj && tj.adresse ? <div className={"d-flex flex-column"}>
                                    <div className={"texte-gros mb-5 text-center"}>
                                        Envoyer le courrier au :<br/> {tj.nom} <br/>
                                        Service des requêtes en injonctions de payer<br/>
                                        {tj.adresse}<br/>
                                        {tj.codepostal} {tj.ville}
                                    </div>

                                    {!requete.test && totalEnvoi !== 0 && !mfisinactive ?
                                        <div className={"texte-gros mb-5 text-center"}>
                                            L'envoi du courrier AR vous sera facturé {totalEnvoi} € TTC
                                        </div> : null}
                                    {requete.requete_pdf ?
                                        (!idEnvoi.requete_id && !loadingSendCourrier && tj.nom && totalEnvoi !== 0 && !mfisinactive ?
                                            <Btn className={"mb-5"}
                                                 texte={"Confirmer l'envoi de la requête au tribunal"}
                                                 iconeGauche={"signer"}
                                                 loading={true}
                                                 onClick={async (endLoading) => {
                                                     openModal(document.getElementById("modal_confirmer_envoi_requete"));
                                                     endLoading();
                                                 }}/> : null) :
                                        <div className={"texte-standard text-center"}>Générer votre requête définitive
                                            avant
                                            d'envoyer votre courrier.</div>}

                                </div> : <div className={"d-flex flex-column"}>
                                    {loadingTJ ?
                                        <Alert variant={"info"} className={"mb-5 text-center mt-5"}>Recherche du
                                            tribunal compétent
                                            ...</Alert> :
                                        <Alert variant={'danger'} className={"texte-gros mb-5 text-center"}>Nous n'avons
                                            pas trouvé
                                            de tribunal judiciaire
                                            dans notre base de données à partir du code postal de votre débiteur.
                                            Complétez
                                            manuellement
                                            l'adresse dans le document.
                                        </Alert>}
                                    <Btn texte={"Modifier le code postal du débiteur"} iconeGauche={"flecheGauche"}
                                         loading={true}
                                         onClick={async (endLoading) => {
                                             await updateRequete(id, {etape: 1});
                                             setEtape(1);
                                             endLoading();
                                         }}/></div>)}

                            <Alert
                                className={"d-flex mb-10 mt-3 justify-content-center flex-column align-items-center"}>
                                <p className={"d-flex mt-3"}>Étape suivante : J'ai envoyé ma requête au tribunal et le
                                    tribunal m'a
                                    renvoyé une ordonnance de payer.</p>
                                <Btn texte={"Transmettre mon dossier à un commissaire de justice"} loading={true}
                                     onClick={async (endLoading) => {
                                         setEtape(16);
                                         await updateRequete(id, {etape: 16});
                                         endLoading();
                                     }}/>
                            </Alert>

                            <div
                                className={"container d-flex justify-content-center align-content-center mt-5 gap-4  pb-2"}>
                                <Btn texte={"Précédent"} iconeGauche={"flecheGauche"} loading={true} onClick={() => {
                                    updateRequete(id, {etape: 9});
                                    setEtape(9)
                                }}/>
                            </div>

                            <div
                                className={"container d-flex justify-content-center align-content-center mt-5 gap-4  pb-2"}>
                                {/*!loadingSendCourrier ? <Btn texte={"Précédent"} iconeGauche={"flecheGauche"} loading={true}
                                                 onClick={async (endLoading) => {
                                                     await updateRequete(id, {etape: 9});
                                                     setEtape(9);
                                                     endLoading();
                                                 }}/> : null*/}
                            </div>
                            <Modal id={"modal_confirmer_envoi_requete"} titre={"Confirmation envoi requête"}
                                   body={<div>
                                       <div>
                                           <div className={"mb-3"}>Montant débité : {totalEnvoi} €
                                               TTC
                                           </div>
                                           <Btn texte="Payer l'envoi" loading={true} onClick={async (endLoading) => {
                                               let result = await payerEnvoiCourrier(id, "requete");
                                               if (result.message === "ok") {
                                                   document.location.href = result.url;
                                               }
                                               endLoading();
                                           }}/>
                                           {/*pm.length > 0 ? <select className={"form-select my-3"} onChange={(e) => {
                                       setCurrentPM(e.target.value);
                                   }}>
                                       <option value={""}>-- Choisissez votre moyen de paiement --</option>
                                       {pm.map((element) => {
                                           return (
                                               <option value={element.id} key={element.id}>{element.card.brand} | XXXX XXXX
                                                   XXXX {element.card.last4} | {element.card.exp_month}/{element.card.exp_year}</option>)
                                       })}
                                   </select> :
                                   <div className={"alert alert-danger mt-2"}>Vous n'avez pas de carte configuré sur
                                       votre compte pour payer, <span className={"btn-link"} onClick={() => {
                                           getPortalURL(utilisateur.stripe_customer_id, urlSite + "/dashboard/requete/" + id);
                                       }
                                       }>cliquez ici</span> pour en ajouter une.</div>*/}

                                       </div>
                                       <Alert variant={"info mt-3"}>
                                           Attention une fois la demande d'ordonnance de payer envoyée, vous ne pourrez
                                           plus la
                                           modifier
                                       </Alert>
                                   </div>}>
                                {totalEnvoi !== 0 && currentPM ?
                                    <Btn texte="Confirmer" loading={true} onClick={async (endLoading) => {
                                        let payement = await createPayement(Math.round(totalEnvoi * 100), utilisateur.stripe_customer_id, currentPM, utilisateur.id, urlSite + "/dashboard/requete/" + id, id, "requete");
                                        if (payement.type === "StripeCardError") {
                                            alert("Votre carte n'a pas pû être prélévée. Veuillez changer de carte en vous rendant dans vos moyens de paiement.");
                                            endLoading();
                                        } else if (payement.type === "StripeInvalidRequestError") {
                                            alert(payement.raw.message);
                                            endLoading();
                                        } else {
                                            setPi(payement.id);
                                        }
                                    }}/> : null}
                                <Btn texte="Annuler" onClick={async () => {
                                    closeModal(document.getElementById("modal_confirmer_envoi_requete"));
                                }}/>
                            </Modal>
                        </div> : null}
                    {etape === 11 ?
                        <div className={"etape8 d-flex justify-content-center align-items-center flex-column"}>
                            <div className={"texte-gros mb-5 text-center"}>
                                Et maintenant ?<br/>
                                <br/>
                                Votre requête a été transmise au tribunal compétent.<br/>
                                <br/>
                                Une fois votre requête traitée vous allez recevoir par la poste de la part du tribunal
                                une ordonnance de payer.<br/>
                                <br/>
                                A partir de ce moment là vous pourrez saisir un commissaire de justice de votre choix à
                                qui vous
                                allez transmettre
                                ce document.<br/>
                                <br/>
                                Le commissaire de justice se chargera de recouvrir votre créance.
                            </div>
                            <Btn className={"mb-2"} texte={"Téléchargez la requête"} iconeGauche={"download"}
                                 loading={false}
                                 onClick={async (endLoading) => {
                                     window.open("/demande-injonction/" + id);
                                 }}/>
                            <Btn className={"mb-2"} texte={"Créer un nouveau dossier"} loading={false}
                                 onClick={async (endLoading) => {
                                     document.location.href = "/dashboard/nouvelle-requete";
                                 }}/>
                            <Btn className={"mb-2"} texte={"Aller au suivi des dossiers"} loading={false}
                                 onClick={async (endLoading) => {
                                     document.location.href = "/dashboard/requete-en-cours";
                                 }}/>
                        </div> : null}
                    {etape === 12 ? <div className={"etape2 container px-0"}>
                        <div className={"texte-gros mb-5 text-center"}>
                            SIRET de votre débiteur :
                        </div>
                        <form className={"form-default needs-validation"} id={"formSociete"} ref={formSociete}
                              name={"formSociete"}
                              noValidate={true}>
                            <div className={"row g-3 mb-3"}>
                                <div className={"col-12"}>
                                    {fiche_identite?.Data?.EtatRadiation === "true" ?
                                        <div className={"alert alert-danger mt-2"}>Votre société a été radiée, vous ne
                                            pouvez
                                            pas demander une requête d'ordonnance de payer.</div> : ""}
                                    {fiche_identite?.Code === 404 ?
                                        <div className={"alert alert-danger mt-2"}>Votre société n'a pas été
                                            trouvée.</div> : ""}
                                    <input type={"text"} className={"form-control"} id={"SIREN"}
                                           placeholder={"Numéro SIREN"} value={SIRENDebiteur} onChange={(event) => {
                                        setSIRENDebiteur(event.target.value)
                                    }}
                                           required pattern={"^[0-9]{9}$"}/>
                                    <div className="invalid-feedback">
                                        Le format du SIREN n'est pas correct.
                                    </div>
                                </div>
                            </div>
                            <div className={"col-12"}>
                                <div className={"d-flex flex-column flex-md-row"}>
                                    <Btn texte={"Valider le SIREN"} iconeDroite={"fleche"} onClick={checkSIREN}
                                         loading={true}/>
                                </div>
                            </div>
                            {SIREN_ok && SIRENDebiteur ? <div className={"row g-3 mt-3"}>
                                <div className={"col-12"}>
                                    <input type={"text"} className={"form-control"} id={"RaisonSociale"}
                                           placeholder={"Raison sociale"} value={RaisonSocialeDebiteur}
                                           onChange={(event) => {
                                               setRaisonSocialeDebiteur(event.target.value)
                                           }} required/>
                                    <div className="invalid-feedback">
                                        La raison sociale n'est pas correcte.
                                    </div>
                                </div>
                                <div className={"col-12"}>
                                    <input type={"text"} className={"form-control"} id={"Adresse"}
                                           placeholder={"Adresse"} value={adresseDebiteur} onChange={(event) => {
                                        setAdresseDebiteur(event.target.value)
                                    }}
                                           required/>
                                    <div className="invalid-feedback">
                                        L'adresse n'est pas correcte.
                                    </div>
                                </div>
                                <div className={"col-12"}>
                                    <input type={"text"} className={"form-control"} id={"CodePostal"}
                                           placeholder={"Code postal"} pattern={"^[0-9]{5}$"} value={codePostalDebiteur}
                                           onChange={async (event) => {
                                               setCodePostalDebiteur(event.target.value)
                                               let ville = await getVilleFromCodePostal(event.target.value);
                                               setVilleDebiteur(ville);
                                           }} required/>
                                    <div className="invalid-feedback">
                                        Le code postal n'est pas correct.
                                    </div>
                                </div>
                                <div className={"col-12"}>
                                    <input type={"text"} className={"form-control"} id={"Ville"}
                                           placeholder={"Ville"} value={villeDebiteur} onChange={(event) => {
                                        setVilleDebiteur(event.target.value)
                                    }} required/>
                                    <div className="invalid-feedback">
                                        La ville n'est pas correcte.
                                    </div>
                                </div>


                                <div className={"col-12"}>
                                    <select className={"form-control"} value={formeJuridiqueDebiteur}
                                            id={"FormeJuridique"}
                                            placeholder={"Forme juridique"} required onChange={(event) => {
                                        setFormeJuridiqueDebiteur(event.target.value)
                                    }}>
                                        <option value="">--- Forme juridique ---</option>
                                        {Object.keys(formesJuridiques).map((element, index) => {
                                            return (<option value={formesJuridiques[element].libelle}
                                                            key={index}>{formesJuridiques[element].libelle}</option>);
                                        })}
                                    </select>
                                    <div className="invalid-feedback">
                                        La forme juridique n'est pas correcte.
                                    </div>
                                </div>
                                {isSocieteCivile(formeJuridiqueDebiteur) ?
                                    <div className={"col-12 gap-3 d-flex flex-column"}>
                                        <div className={"col-12"}>
                                            <input type={"text"} className={"form-control"} id={"Prenom"}
                                                   placeholder={"Prénom du débiteur"} value={prenomDebiteur}
                                                   onChange={(event) => {
                                                       setPrenomDebiteur(event.target.value)
                                                   }} required/>
                                            <div className="invalid-feedback">
                                                Le prénom n'est pas correct.
                                            </div>
                                        </div>

                                        <div className={"col-12"}>
                                            <input type={"text"} className={"form-control"} id={"Nom"}
                                                   placeholder={"Nom du débiteur"} value={nomDebiteur}
                                                   onChange={(event) => {
                                                       setNomDebiteur(event.target.value)
                                                   }} required/>
                                            <div className="invalid-feedback">
                                                Le nom n'est pas correct.
                                            </div>
                                        </div>


                                    </div> : null}

                                <div className={"col-12"}>
                                    <input type={"email"} className={"form-control"} id={"Email"}
                                           placeholder={"Email du débiteur"} value={emailDebiteur}
                                           onChange={(event) => {
                                               setEmailDebiteur(event.target.value)
                                           }} required/>
                                    <div className="invalid-feedback">
                                        L'email n'est pas correct.
                                    </div>
                                </div>

                                <div className={"col-12"}>
                                    <input type={"tel"} className={"form-control"} id={"Telephone"}
                                           placeholder={"Téléphone du débiteur"} value={telephoneDebiteur}
                                           onChange={(event) => {
                                               setTelephoneDebiteur(event.target.value)
                                           }} required/>
                                    <div className="invalid-feedback">
                                        Le téléphone n'est pas correct.
                                    </div>
                                </div>

                                <div className={"d-flex flex-column"}>
                                    <div className={"mb-4"}>Uploadez le kbis de votre débiteur (obligatoire) :</div>
                                    <Btn texte={"Charger"} iconeDroite={"upload"} onClick={() => {
                                        document.getElementById("kbis_debiteur").click()
                                    }}/>
                                    <progress id="uploader" className={"opacity-0 container-fluid"} value="0"
                                              max="100"></progress>
                                    {KBISDebiteur ?
                                        <div
                                            className={"d-flex flex-column flex-md-row justify-content-center align-items-center mb-5 mt-2"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                 fill="currentColor"
                                                 className="bi bi-file-check" viewBox="0 0 16 16">
                                                <path
                                                    d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                                <path
                                                    d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                                            </svg>
                                            Document téléchargé :  &nbsp;<a className={"violet"} rel="noreferrer"
                                                                            target={"_blank"} href={KBISDebiteur.url}>KBIS
                                            débiteur</a></div> : null}
                                </div>
                                <input type="file" id="kbis_debiteur" className={"d-none"} multiple={false}
                                       onChange={uploadKbisDebiteur}/>


                                <div className={"my-3"}></div>
                            </div> : null}

                            <Modal id={"confirmation"} titre={"Société mise à jour"}
                                   body={"Les informations de votre société ont bien été mise à jour."}>
                                <Btn texte="OK" onClick={() => {
                                    closeModal(document.getElementById("confirmation"))
                                }}/>
                            </Modal>
                        </form>
                        <div className={"container d-flex justify-content-center align-content-center mt-5 gap-4 pb-2"}>
                            <Btn texte={"Précédent"} iconeGauche={"flecheGauche"} loading={true} onClick={() => {
                                updateRequete(id, {etape: 1});
                                setEtape(1)
                            }}/>
                            {SIREN_ok ? <Btn texte={"Suivant"} iconeDroite={"fleche"} onClick={() => {
                                openModal(document.getElementById("modal_confirm_procedure_collective"));
                            }}/> : null}
                            <Modal id={"modal_confirm_procedure_collective"} titre={"Procédure collective"}
                                   body={"Cette entreprise est elle en  redressement judiciaire, ou en liquidation judiciaire ?"}>
                                <Btn texte="Non" loading={true} onClick={async (endLoading) => {
                                    closeModal(document.getElementById("modal_confirm_procedure_collective"));
                                    await submitForm(endLoading);
                                }}/>
                                <Btn texte="Oui" loading={true} onClick={async (endLoading) => {
                                    closeModal(document.getElementById("modal_confirm_procedure_collective"));
                                    openModal(document.getElementById("modal_avocat"));
                                    endLoading();
                                }}/>
                            </Modal>
                            <Modal id={"modal_kbis_debiteur"} titre={"KBIS débiteur"}
                                   body={"N'oubliez pas d'uploader le KBIS de votre débiteur. Vous pouvez en télécharger un directement sur infogreffe."}>
                                <Btn texte="OK" loading={true} onClick={async (endLoading) => {
                                    closeModal(document.getElementById("modal_kbis_debiteur"));
                                    endLoading();
                                }}/>
                                <Btn texte="Se rendre sur infogreffe" loading={true} onClick={async (endLoading) => {
                                    window.open("https://www.infogreffe.fr/documents-officiels/extrait-kbis");
                                    endLoading();
                                }}/>
                            </Modal>
                            <Modal id={"modal_avocat"} titre={"Procédure collective"}
                                   body={"L’entreprise débitrice est en redressement ou en liquidation judiciaire. Lorsque ces procédures sont en cours, les poursuites des créanciers sont en principe suspendues. Il n’est donc pas possible de déposer une requête en injonction de payer et votre dossier ne peut être finalisé. Il existe des procédures spécifiques. Nous vous invitons à vous rapprocher d’un avocat."}>
                                <Btn texte="OK" loading={true} onClick={async (endLoading) => {
                                    closeModal(document.getElementById("modal_avocat"));
                                    endLoading();
                                }}/>
                            </Modal>
                        </div>
                    </div> : null}
                    {etape === 13 ?
                        <div className={"etape13 d-flex justify-content-center align-items-center flex-column"}>
                            <div className={"texte-gros mb-5 text-center"}>
                                Générer le courrier de mise en demeure
                            </div>
                            {loadingData ?
                                <img alt={"Chargement en cours ..."} width={50}
                                     src={"../../assets/img/spinner_gris.svg"}/> :
                                <div
                                    className={"d-flex gap-2 flex-column justify-content-center align-items-center mb-5"}>
                                    {envoiMED && envoiMED.error !== "Not found" ?
                                        <div className={"big_btn"} onClick={async () => {
                                            setEtape(14);
                                        }}>Voir le suivi de l'envoi.
                                        </div> : (tarifPageMiseEnDemeure && !mfisinactive ?
                                            <div className={"big_btn"} onClick={async () => {
                                                openModal(document.getElementById("modal_confirmer_envoi_med"));
                                            }}>Je génère et j’envoie immédiatement
                                                une mise en demeure sans me déplacer
                                                via factures impayées et factures impayées m’informe lorsque je peux
                                                finaliser mon
                                                dossier<br/>
                                                Tarif forfaitaire : {tarifPageMiseEnDemeure} € TTC.

                                            </div> : null)}
                                    <div className={"big_btn"} onClick={async (endLoading) => {
                                        window.open("/mise-en-demeure/" + id);
                                    }}>Je génère, j’imprime, et j’envoie la mise en demeure en RAR et je
                                        reviens plus tard avec l’AR
                                        pour reprendre le cours de mon dossier.
                                    </div>
                                    <div className={"big_btn"} onClick={async (endLoading) => {
                                        setEtape(6);
                                        updateRequete(id, {etape: 6});
                                    }}>J’ai reçu mon accusé de réception, j’ai respecté le délai 15 jours,
                                        je n’ai pas été payé, je poursuis mon dossier.
                                    </div>
                                </div>}

                            <Modal id={"modal_confirmer_envoi_med"} titre={"Confirmation envoi mise en demeure"}
                                   body={<div>
                                       <div>
                                           <div className={"mb-3"}>Montant débité : {tarifPageMiseEnDemeure} €
                                               TTC
                                           </div>
                                           <Btn texte="Payer l'envoi" loading={true} onClick={async (endLoading) => {
                                               let result = await payerEnvoiCourrier(id, "miseendemeure");
                                               if (result.message === "ok") {
                                                   document.location.href = result.url;
                                               }
                                               endLoading();
                                           }}/>
                                           {/*pm.length > 0 ? <select className={"form-select my-3"} onChange={(e) => {
                                       setCurrentPM(e.target.value);
                                   }}>
                                       <option value={""}>-- Choisissez votre moyen de paiement --</option>
                                       {pm.map((element) => {
                                           return (
                                               <option value={element.id} key={element.id}>{element.card.brand} | XXXX XXXX
                                                   XXXX {element.card.last4} | {element.card.exp_month}/{element.card.exp_year}</option>)
                                       })}
                                   </select> :
                                   <div className={"alert alert-danger mt-2"}>Vous n'avez pas de carte configuré sur
                                       votre compte pour payer, <span className={"btn-link"} onClick={() => {
                                           getPortalURL(utilisateur.stripe_customer_id, urlSite + "/dashboard/requete/" + id);
                                       }
                                       }>cliquez ici</span> pour en ajouter une.</div>*/}
                                       </div>
                                       <Alert variant={"info"} className={"mt-3"}>
                                           Attention une fois la mise en demeure envoyée, vous ne pourrez plus la
                                           modifier
                                       </Alert>
                                   </div>}>
                                {/*tarifPageMiseEnDemeure !== 0 && currentPM ?
                        <Btn texte="Confirmer" loading={true} onClick={async (endLoading) => {
                            let payement = await createPayement(Math.round(tarifPageMiseEnDemeure * 100), utilisateur.stripe_customer_id, currentPM, utilisateur.id, urlSite + "/dashboard/requete/" + id, id, "miseendemeure");
                            if (payement.type === "StripeCardError") {
                                alert("Votre carte n'a pas pû être prélévée. Veuillez changer de carte en vous rendant dans vos moyens de paiement.");
                                endLoading();
                            } else if (payement.type === "StripeInvalidRequestError") {
                                alert(payement.raw.message);
                                endLoading();
                            } else {
                                setPi(payement.id);
                            }
                        }}/> : null*/}
                                <Btn texte="Annuler" onClick={async () => {
                                    closeModal(document.getElementById("modal_confirmer_envoi_med"));
                                }}/>
                            </Modal>

                            <iframe className={"d-none"} title={"Mise en demeure"}
                                    src={"/mise-en-demeure/" + id}></iframe>
                            {/*<Btn className={"mb-2"} texte={"Précédent"} iconeGauche={"flecheGauche"} loading={false}
                     onClick={async (endLoading) => {
                         setEtape(5);
                         updateRequete(id, {etape: 5});
                     }}/>*/}


                        </div> : null}
                    {etape === 14 ?
                        <div className={"etape14 d-flex justify-content-center align-items-center flex-column"}>
                            <div className={"texte-gros mb-1 text-center"}>
                                Revenez dans un délai contractuel de 15 jours :
                            </div>
                            <div className={"mb-5 text-center"}>
                                délai légal mise en demeure + réception courrier
                                <ProgressBar now={progressEnvoi} animated/>
                            </div>

                            <div className={"texte-gros mb-5 text-center"}>
                                Votre mise en demeure a été envoyée via factures impayées.
                            </div>
                            <div className={"d-flex gap-2 flex-column justify-content-center align-items-center mb-5"}>
                                {suiviMED ? <div>
                                    <div>Envoi : {suiviMED.success === true ? "OK" : "Erreur"}</div>
                                    <div>Statut : {decodeHTMLEntities(suiviMED.statutPrintEnvoi)}</div>
                                    {suiviMED?.suiviCourrier ?
                                        <div>
                                            <div>Destinataire : {decode(suiviMED.suiviCourrier[0]?.dest)}</div>
                                            <div>Reférence interne
                                                : {decode(suiviMED.suiviCourrier[0]?.refCourrier)}</div>
                                            <div>Référence de suivi postal
                                                : {decode(suiviMED.suiviCourrier[0]?.refTracking)}</div>
                                            <div>Mode d'envoi du courrier
                                                : {decode(suiviMED.suiviCourrier[0]?.modeEnvoi)}</div>
                                            <div>Dernier état connu de l'acheminement
                                                : {decode(suiviMED.suiviCourrier[0]?.lastState)}</div>
                                            <div>Historique complet de l'acheminement : <span
                                                dangerouslySetInnerHTML={{__html: decode(suiviMED.suiviCourrier[0]?.historique)}}></span>
                                            </div>
                                            {suiviMED.suiviCourrier[0]?.refTracking ?
                                                <div>Document de suivi :
                                                    <a href={"/reception/" + suiviMED.suiviCourrier[0]?.refTracking}
                                                       rel="noreferrer" target={"_blank"}>Télécharger</a></div> : null}
                                        </div>
                                        : null}
                                </div> : null}
                                <div>Une fois l'accusé de réception reçu, vous pourrez le télécharger et passer à
                                    l'étape
                                    suivante.
                                </div>
                                <div className={"d-flex gap-3"}>
                                    <Btn texte={"Précédent"} iconeGauche={"flecheGauche"} loading={true}
                                         onClick={async () => {
                                             setEtape(13);
                                             updateRequete(id, {etape: 13});
                                         }}/>
                                    {suiviMED?.suiviCourrier && suiviMED.suiviCourrier[0]?.refTracking ?
                                        <Btn texte={"Suivant"} iconeDroite={"fleche"} loading={true}
                                             onClick={async () => {
                                                 setEtape(6);
                                                 await updateRequete(id, {etape: 6});
                                             }}/> : null}
                                </div>
                            </div>
                        </div> : null}
                </>}
            <Modal id={"modal_format_fichier"} titre={"Mauvais format"}
                   body={"Le type du fichier uploadé n'est pas correct, le type doit être : PDF, JPG ou PNG."}>
                <Btn texte="Ok" onClick={async () => {
                    closeModal(document.getElementById("modal_format_fichier"));
                }}/>
            </Modal>
        </div>
    );
}