import React, {useEffect, useState} from "react";
import Btn from "../../components/Btn";
import {Modal, openModal, closeModal} from "../../components/Modal";
import {getFicheIdentite} from "../../class/Infogreffe";
import {getCurrentUser, getUserFromUid, updateUser} from "../../class/User";
import {createRequete, getVilleFromCodePostal} from "../../class/Requete";
import {Helmet} from 'react-helmet';
import ModalNouvelleRequete from "../../components/ModalNouvelleRequete";
//import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
//import {firebaseApp} from "../../components/Firebase";

export default function MaSociete({ type }) {

    const [SIREN, setSIREN] = useState("");
    //const [SIREN_error, setSIREN_error] = useState(false);
    const [SIREN_ok, setSIREN_ok] = useState(false);
    const [fiche_identite, setfiche_identite] = useState({});
    const [RaisonSociale, setRaisonSociale] = useState("");
    const [Adresse, setAdresse] = useState("");
    const [CodePostal, setCodePostal] = useState("");
    const [Ville, setVille] = useState("");
    const [Prenom, setPrenom] = useState("");
    const [Nom, setNom] = useState("");
    const [Qualite, setQualite] = useState("");
    const [Telephone, setTelephone] = useState("");
    const [Titre, setTitre] = useState("");
    const [Type, setType] = useState("");
    //const [KBIS, setKBIS] = useState("");
    const [creerRequete, setCreerRequete] = useState(false);

    useEffect(() => {


        const getData = async () => {
            let user = await getCurrentUser();
            let societe = await getUserFromUid(user.uid);
            //setSIREN_error(false);
            if (societe.SIREN) {
                setSIREN(societe.SIREN);
                setSIREN_ok(true);
            }
            setfiche_identite(societe.fiche_identite);
            setRaisonSociale(societe.RaisonSociale);
            setAdresse(societe.Adresse);
            setCodePostal(societe.CodePostal);
            setVille(societe.Ville);
            setPrenom(societe.Prenom);
            setNom(societe.Nom);
            setQualite(societe.Qualite);
            setTelephone(societe.Telephone);
            setTitre(societe.Titre);
            setType(societe.Type);
            //setKBIS(societe.kbis);
        }
        getData();
    }, []);


    const checkSIREN = async () => {
        let form = document.getElementById("formSociete");
        if (!form.checkValidity()) {

        } else {
            if (SIREN === "" || SIREN.length !== 9 || isNaN(SIREN)) {
                //setSIREN_error(true);
            } else {
                //setSIREN_error(false);
                let ficheIdentite = await getFicheIdentite(SIREN);
                setfiche_identite(ficheIdentite);
                if (ficheIdentite?.Data?.EtatRadiation !== "true" && ficheIdentite?.Code !== 404) {
                    setSIREN_ok(true);
                    setRaisonSociale(ficheIdentite.Data.Denomination);
                    setAdresse(ficheIdentite.Data.Adresse.AdresseConcat);
                    setCodePostal(ficheIdentite.Data.Adresse.CodePostal);
                    setVille(ficheIdentite.Data.Adresse.BureauDistributeur);
                } else {
                    setSIREN_ok(false);
                }
            }
        }
        form.classList.add('was-validated');
        //stopLoading();
    }

    const changeSIREN = (event) => {
        setSIREN(event.target.value);
        checkSIREN();
    }

    const submitForm = async (stopLoading) => {
        window.lintrk('track', { conversion_id: 21107105 });
        console.log("Submit linkedin conversion");
        let form = document.getElementById("formSociete");
        if (!form.checkValidity()) {

        } else {

            let user = await getCurrentUser();
            let response = await updateUser(user.uid, {
                SIREN: SIREN,
                fiche_identite: fiche_identite,
                RaisonSociale: RaisonSociale,
                Adresse: Adresse,
                CodePostal: CodePostal,
                Ville: Ville,
                Prenom: Prenom,
                Nom: Nom,
                Qualite: Qualite,
                Telephone: Telephone,
                Titre: Titre,
                Type: Type
            });
            if (response.message === "Utilisateur mis à jour") {
                setCreerRequete(true);
                openModal(document.getElementById("confirmation"));
            }
        }


        form.classList.add('was-validated');
        stopLoading();
    }

    /*const uploadKBIS = (event) => {
        const uploader = document.getElementById('uploader_kbis');
        uploader.className = "opacity-100 container-fluid";
        const storage = getStorage(firebaseApp);
        const files = event.target.files;
        let progress = [];
        let file = files[0];
        let type = file.type;
        let extension = "";
        if (type === "image/jpeg") {
            extension = "jpg";
        } else if (type === "image/png") {
            extension = "png";
        } else if (type === "application/pdf") {
            extension = "pdf";
        } else {
            openModal(document.getElementById("modal_format_fichier"));
        }
        if (extension !== "") {
            const storageRef = ref(storage, 'KBIS/' + (Date.now().toString(36) + Math.random().toString(36).substring(2)) + "." + extension);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    uploader.value = progress;

                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                        default:

                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                        default:
                    }
                },
                async () => {
                    // Upload completed successfully, now we can get the download URL
                    await getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        console.log('File available at', downloadURL);
                        let user = await getCurrentUser();
                        await updateUser(user.uid, {kbis: downloadURL, kbisType: type});
                        setKBIS(downloadURL);
                    });
                }
            );
        }
    }*/


    return (
        <div className={"container-fluid"}>
            <Helmet>
                <title>Informations sur votre société sur facturesimpayees.com</title>
                <meta name="description"
                      content="Saisissez les informations sur votre société sur facturesimpayees.com pour gérer efficacement vos factures impayées. Complétez vos données pour bénéficier de toutes les fonctionnalités de notre plateforme."/>
                <meta name="keywords"
                      content="informations société, factures impayées, plateforme, fonctionnalités, facturesimpayees.com"/>
            </Helmet>
            <h2 className={"dashboard-title"}>Ma société</h2>
            <form className={"form-default needs-validation"} id={"formSociete"} name={"formSociete"}
                  noValidate={true}>
                <div className={"row g-3 mb-3"}>
                    <div className={"col-12"}>
                        {fiche_identite?.Data?.EtatRadiation === "true" ?
                            <div className={"alert alert-danger mt-2"}>Votre société a été radiée, vous ne pouvez
                                pas utiliser la plateforme avec ce SIREN.</div> : ""}
                        {fiche_identite?.Code === 404 ?
                            <div className={"alert alert-danger mt-2"}>Votre société n'a pas été
                                trouvée.</div> : ""}
                        <input type={"text"} className={"form-control"} id={"SIREN"}
                               placeholder={"Numéro SIREN"} value={SIREN} onChange={changeSIREN}
                               required pattern={"^[0-9]{9}$"}/>
                        <div className="invalid-feedback">
                            Le format du SIREN n'est pas correct.
                        </div>
                    </div>
                </div>
                <div className={"col-12"}>
                    <div className={"d-flex flex-column flex-md-row"}>
                        <Btn className={type==="commissaireDeJustice"?"btn-vert ":""||type==="avocat"?"btn_avocat ":""} texte={"Valider le SIREN"} iconeDroite={"fleche"} onClick={async (endLoading) => {
                            await checkSIREN();
                            endLoading();
                        }}
                             loading={true}/>
                    </div>
                </div>
                {SIREN_ok ? <div className={"row g-3 mt-3"}>
                    <div className={"col-12"}>
                        <input type={"text"} className={"form-control"} id={"RaisonSociale"}
                               placeholder={"Raison sociale"} value={RaisonSociale}
                               onChange={(e) => {
                                   setRaisonSociale(e.target.value)
                               }} required/>
                        <div className="invalid-feedback">
                            La raison sociale n'est pas correcte.
                        </div>
                    </div>
                    <div className={"col-12"}>
                        <input type={"text"} className={"form-control"} id={"Adresse"}
                               placeholder={"Adresse"} value={Adresse} onChange={(e) => {
                            setAdresse(e.target.value)
                        }}
                               required/>
                        <div className="invalid-feedback">
                            L'adresse n'est pas correcte.
                        </div>
                    </div>
                    <div className={"col-12"}>
                        <input type={"text"} className={"form-control"} id={"CodePostal"}
                               placeholder={"Code postal"} pattern={"^[0-9]{5}$"} value={CodePostal}
                               onChange={async (e) => {
                                   setCodePostal(e.target.value);
                                   let ville = await getVilleFromCodePostal(e.target.value);
                                   setVille(ville)
                               }} required/>
                        <div className="invalid-feedback">
                            Le code postal n'est pas correct.
                        </div>
                    </div>
                    <div className={"col-12"}>
                        <input type={"text"} className={"form-control"} id={"Ville"}
                               placeholder={"Ville"} value={Ville} onChange={(e) => {
                            setVille(e.target.value)
                        }} required/>
                        <div className="invalid-feedback">
                            La ville n'est pas correcte.
                        </div>
                    </div>
                    <div className={"my-3"}></div>

                    <div className={"col-12"}>
                        <select className={"form-control"} id={"Titre"} value={Titre} onChange={(e) => {
                            setTitre(e.target.value)
                        }} required>
                            <option value={""}>-- Selectionnez un titre --</option>
                            <option value={"Madame"}>Madame</option>
                            <option value={"Monsieur"}>Monsieur</option>
                        </select>
                        <div className="invalid-feedback">
                            Selectionnez un titre
                        </div>
                    </div>
                    <div className={"col-12"}>
                        <input type={"text"} className={"form-control"} id={"Prenom"}
                               placeholder={"Prénom"} value={Prenom} onChange={(e) => {
                            setPrenom(e.target.value)
                        }}
                               required/>
                        <div className="invalid-feedback">
                            Le prénom n'est pas correct.
                        </div>
                    </div>
                    <div className={"col-12"}>
                        <input type={"text"} className={"form-control"} id={"Nom"}
                               placeholder={"Nom"} value={Nom} onChange={(e) => {
                            setNom(e.target.value)
                        }} required/>
                        <div className="invalid-feedback">
                            Le nom n'est pas correct.
                        </div>
                    </div>
                    <div className={"col-12"}>
                        <input type={"text"} className={"form-control"} id={"Qualite"}
                               placeholder={"Qualité"} value={Qualite} onChange={(e) => {
                            setQualite(e.target.value)
                        }}
                               required/>
                        <div className="invalid-feedback">
                            La qualité n'est pas correcte.
                        </div>
                    </div>
                    <div className={"col-12"}>
                        <input type={"tel"} className={"form-control"} id={"Telephone"}
                               placeholder={"Téléphone"} value={Telephone} onChange={(e) => {
                            setTelephone(e.target.value)
                        }} required/>
                        <div className="invalid-feedback">
                            Le téléphone n'est pas correct.
                        </div>
                    </div>
                    <div className={"col-12"}>
                        <select className={"form-control"} value={Type} id={"Type"} onChange={(e) => {
                            setType(e.target.value)
                        }} required>
                            <option selected={Type === "Autre"}>Autre</option>
                            <option selected={Type === "Commissaire de justice"}>Commissaire de justice</option>
                            <option selected={Type === "Avocat"}>Avocat</option>
                        </select>
                        <div className="invalid-feedback">
                            Le type n'est pas correct.
                        </div>
                    </div>
                    <div className={"col-12"}>
                        <div className={"d-flex flex-column flex-md-row mb-3"}>
                            <Btn className={type === "commissaireDeJustice" ? "btn-vert " : ""||type === "avocat" ? "btn_avocat " : ""} texte={"Enregistrer"}
                                 iconeDroite={"fleche"} onClick={submitForm} loading={true}/>
                            {creerRequete && type !== "commissaireDeJustice" && type !== "avocat" ?
                                <Btn texte={"Créer un nouveau dossier"} iconeDroite={"fleche"} onClick={createRequete} loading={true} className={"ms-3"}/> : null}
                        </div>
                    </div>
                </div> : null}

                <Modal id={"confirmation"} titre={"Société mise à jour"}
                       body={"Les informations de votre société ont bien été mise à jour."}>
                    <Btn texte="OK" onClick={() => {
                        closeModal(document.getElementById("confirmation"))
                    }}/>
                </Modal>
                <Modal id={"modal_format_fichier"} titre={"Mauvais format"}
                       body={"Le type du fichier uploadé n'est pas correct, le type doit être : PDF, JPG ou PNG."}>
                    <Btn texte="Ok" onClick={async () => {
                        closeModal(document.getElementById("modal_format_fichier"));
                    }}/>
                </Modal>
            </form>
            <ModalNouvelleRequete/>
        </div>

    );
}
